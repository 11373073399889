import axios from "axios";
import config from "../config";
import authApi from "../services/auth";

const axiosInstance = axios.create({
  baseURL: config?.api?.API_URL, // Replace with your API base URL
});

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    const auth = JSON.parse(localStorage.getItem("auth") || null);
    const token = auth?.meta?.access_token?.token; // Example of attaching a token from localStorage
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    config.headers["Accept-Language"] = localStorage.getItem("I18N_LANGUAGE");

    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response?.data;
  },
  async (error) => {
    // const originalRequest = error.config;
    const auth = JSON.parse(localStorage.getItem("auth") || null);
    const retry = JSON.parse(localStorage.getItem("retry") || "false");
    if (error?.response?.status === 401) {
      if (!retry && !!auth?.meta) {
        const refresh_token = auth?.meta?.refresh_token?.token;
        localStorage.setItem("retry", true);
        await authApi
          .refreshToken({ refresh_token })
          .then((res) => {
            localStorage.setItem(
              "auth",
              JSON.stringify({
                ...auth,
                meta: {
                  ...auth?.meta,
                  ...res?.data,
                },
              })
            );
          })
          .catch((error) => {
            console.log("error1", error);
          })
          .finally(() => {
            localStorage.setItem("retry", true);
          });
      } else {
        localStorage.setItem("auth", null);
        window.location = "/login";
        localStorage.setItem("retry", false);
      }
    }

    return Promise.reject(error?.response?.data);
  }
);

export default axiosInstance;
