import helper from "helper";
import axiosInstance from "../../core/axiosInstance";

const path = "/v1/auth";
const authApi = {
  login(dataForm) {
    return axiosInstance.post(`${path}/login`, dataForm);
  },

  logout() {
    return axiosInstance.post(`${path}/logout`);
  },

  refreshToken(dataForm) {
    return axiosInstance.post(`${path}/refresh-token`, dataForm);
  },

  signup(type, dataForm) {
    return axiosInstance.post(`${path}/signup/${type}`, dataForm);
  },

  checkPhone(dataForm) {
    return axiosInstance.post(`${path}/check-phone`, dataForm);
  },

  checkEmail(dataForm) {
    return axiosInstance.post(`${path}/check-email`, dataForm);
  },

  sendCode(dataForm) {
    return axiosInstance.post(`${path}/send-code`, dataForm);
  },

  verifyCode(dataForm) {
    return axiosInstance.post(`${path}/verify-code`, dataForm);
  },

  forgotEmail(dataForm) {
    return axiosInstance.post(`${path}/forgot-email`, dataForm);
  },

  forgotPassword(dataForm) {
    return axiosInstance.post(`${path}/forgot-password`, dataForm);
  },

  resetPassword(dataForm) {
    return axiosInstance.post(`${path}/reset-password`, dataForm);
  },

  getProfile() {
    // const user = localStorage.getItem("auth") ? JSON.parse(localStorage.getItem("auth")) : {};
    return axiosInstance.get(`v1/${helper.getUserType()}/profile`);
  },
};

export default authApi;
