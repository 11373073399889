import { createContext, useContext, useState } from "react";

const ToastContext = createContext();

export const ToastContextProvider = ({ children }) => {
  const [toast, setToast] = useState(false);

  const openToast = (mess, type="success"|"error") => {
    setToast({
      mess,
      type,
    });
    
    setTimeout(() => {
      setToast(false);
    }, 5000);
  };

  return (
    <ToastContext.Provider value={{ openToast }}>
      {children}

      <div
        id="toastContext"
        className={`fixed top-[104px] left-0 w-full z-[99999] ${
          toast?.mess ? "show" : "hidden"
        }`}
      >
        <div
          className="max-w-[558px] w-full py-[16px] px-[24px] flex justify-center rounded-[12px] mx-auto"
          style={{ background: toast?.type !== "error" ? "rgba(44, 44, 51, 0.70)" : "#EC2929" }}
        >
          <p className="text-[16px] leading-[24px] text-white">{toast?.mess}</p>
        </div>
      </div>
    </ToastContext.Provider>
  );
};

export default ToastContext;

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error("useModal must be used within a ModalProvider");
  }
  return context;
};
