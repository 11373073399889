import { createAsyncThunk } from "@reduxjs/toolkit";
import systemApi from "../../services/system";

//Include Both Helper File with needed methods
// import categoryApi from "api/categoryApi";

export const getConfigs = createAsyncThunk("Systems", async () => {
  try {
    const response = await systemApi.getConfigs();
    return response;
  } catch (error) {
    return error;
  }
});
