/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, ConfigProvider, Flex, Table } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
//
import { ReactComponent as SearchSvg } from "asset/svg/search.svg";
import { ReactComponent as NextTableSvg } from "asset/svg/nextTable.svg";
import { ReactComponent as PrevTableSvg } from "asset/svg/prevTable.svg";
import { ReactComponent as PlayVideoSvg } from "asset/svg/playVideo.svg";
import { catchAsync } from "helper/api.helper";
import { useToast } from "context/toast.context";
import advertiserApi from "services/advertiser";
import useQueryParams from "hooks/useQueryParams";
import PrSelect from "components/PrSelect";
import PrButton from "components/PrButton";
import PrAutoComplete from "components/PrAutoComplete";
import helper, { removeEmptyFields } from "helper";
import { ATTACHMENT_TYPE, OPTIONS_PRESS_RELEASE, PRESS_RELEASE_LABELS } from "contants/app.contant";
import { formatDate } from "helper/date.helper";
import ModalDetail from "page/advertiser/ModalDetail";
import RenderIfComponent from "components/RenderIfComponent";
import PrRangeDatePicker from "components/PrRangeDatePicker";
import ExpandTable from "./ExpandTable";
import loadingGif from 'asset/images/loading.gif'; 

function ResultAnalysis() {
  const location = useLocation();
  const navigate = useNavigate();
  const optionsPressRelease = OPTIONS_PRESS_RELEASE;
  const [dataSource, setDataSource] = useState([]);
  const [isOpenModal, setIsModalOpen] = useState(false);
  const [idDetail, setIdDetail] = useState(0);
  const [pagination, setPagination] = useState({
    limit: 15,
    page: 1,
    total: 0,
    sort_by: "sent_at",
    order_by: "DESC",
  });
  const [search, setSearch] = useState("");
  const [category, setCategory] = useState(null);
  const [loading, setLoading] = useState(false);
  const [articles, setArticles] = useState([]);

  const optionOrder = [
    {
      value: "DESC",
      label: "최신순",
    },
    {
      value: "ASC",
      label: "과거순",
    },
  ];
  const { openToast } = useToast();
  const { getQueryParams, clearQueryParams, setQueryParams } = useQueryParams({
    limit: 15,
    page: 1,
    // tab: "all",
  });
  const query = getQueryParams();

  const [dateSearch, setDateSearch] = useState([]);

  const columns = [
    {
      title: "번호",
      key: "index",
      width: "64px",
      render: (_, __, index) => {
        return (
          <p className='!text-[16px]'>
            {pagination.total - pagination.limit * (pagination.page - 1) - index}
          </p>
        );
      },
      align: "center",
    },
    {
      title: "보도자료 유형",
      key: "press_release_type",
      dataIndex: "press_release_type",
      width: "200px",
      align: "center",
      render: (value) => {
        return <span className='!text-[16px]'>{PRESS_RELEASE_LABELS[value]}</span>;
      },
    },
    {
      title: "대표 이미지",
      key: "attachment",
      dataIndex: "attachment",
      align: "center",
      width: "100px",
      render: (value) => {
        return (
          <div className='relative w-[56px] h-[42px] mx-auto'>
            <img
              src={
                value?.attachment_type === ATTACHMENT_TYPE.EMBED
                  ? helper.getThumbnailUrlFromIframe(value.url)
                  : value.url
              }
              alt='image-analysis'
              className='mx-auto w-[56px] h-[42px] rounded-[8px] border border-[rgba(0, 0, 0, 0.04)] object-cover'
              onError={helper.handleImageError}
            />
            <RenderIfComponent
              isValid={
                value.attachment_type === ATTACHMENT_TYPE.EMBED ||
                value.attachment_type === ATTACHMENT_TYPE.VIDEO
              }
            >
              <div className='absolute bottom-[2px] right-[2px]'>
                <PlayVideoSvg className='max-w-[18px] max-h-[18px]' />
              </div>
            </RenderIfComponent>
          </div>
        );
      },
    },
    {
      title: "제목",
      key: "title",
      dataIndex: "title",
      align: "center",
      render: (value, { has_publisher_reject, id }) => {
        return (
          <div
            className={`${
              value?.length ? "underline" : ""
            } mx-auto flex items-center !text-[16px] justify-center gap-x-[8px] underline-offset-[3px] cursor-pointer max-w-[612px] line-clamp-1`}
            onClick={() => {
              setIsModalOpen(true);
              setIdDetail(id);
            }}
          >
            {value || "-"} {has_publisher_reject}
          </div>
        );
      },
    },
    {
      title: "전송일",
      key: "sent_at",
      dataIndex: "sent_at",
      width: "100px",
      align: "center",
      render: (value) => {
        return <>{formatDate(value, "YY.MM.DD") || "-"}</>;
      },
    },
    {
      title: "최종 배포일",
      key: "published_at",
      dataIndex: "published_at",
      width: "100px",
      align: "center",
      render: (value) => {
        return <>{formatDate(value, "YY.MM.DD") || "-"}</>;
      },
    },
    {
      title: "매체",
      key: "publishers",
      dataIndex: "publishers",
      width: "160px",
      align: "center",
      render: (value) => {
        return (
          <span className='!text-[16px]'>
            {value?.length
              ? value[0]?.media_name + (value?.length - 1 > 0 ? ` ${value?.length - 1}` : "")
              : "-"}
          </span>
        );
      },
    },
    {
      title: "조회수 합계",
      key: "total_pv",
      dataIndex: "total_pv",
      width: "100px",
      align: "center",
      render: (value) => {
        return <>{helper.numberWithCommas(value) || "0"}</>;
      },
    },
    Table.EXPAND_COLUMN,
  ];

  const getListData = async () => {
    const { time_request, ...queryNew } = query;
    const [err, result] = await catchAsync(
      advertiserApi.getAdvertiserAnalyticsPressReleases(removeEmptyFields(queryNew)),
      setLoading
    );
    if (err) {
      openToast(err?.message, "error");
      return;
    }
    const { list, pagination } = result?.data;
    setDataSource(list);
    setPagination(pagination);
  };

  const handleExpand = (expanded, record) => {
    if (expanded) {
      const parentColumn = document.querySelector(`[data-row-key="${record.id}"] .ant-table-cell`);
      if (parentColumn) {
        parentColumn.parentElement.classList.add("expanded-parent-column");
      }
    } else {
      const parentColumn = document.querySelector(".expanded-parent-column");
      if (parentColumn) {
        parentColumn.classList.remove("expanded-parent-column");
      }
    }
  };

  const handleTableChange = (pagination) => {
    setQueryParams({
      ...query,
      limit: pagination?.pageSize,
      page: pagination?.current,
    });
    document.getElementById("content")?.scrollTo({ top: 0, behavior: "smooth" });
  };

  const listSearch = async (keyword) => {
    if (!keyword?.length) {
      setArticles([]);
      return;
    }
    const [err, result] = await catchAsync(
      advertiserApi.getAdvertiserPressReleaseSuggestTitle(
        removeEmptyFields({
          limit: 15,
          keyword,
        })
      )
    );
    if (err) {
      openToast(err?.message, "error");
      return;
    }
    setArticles(result?.data?.map((item) => ({ value: item.title, label: item.title })));
  };

  useEffect(() => {
    const isCheckTypeUser = helper.isUserAdvertiser();
    if (!isCheckTypeUser) {
      navigate("/media/analysis");
    }
  }, [location.pathname]);

  useEffect(() => {
    getListData();
    if (query?.start_date || query?.end_date) {
      setDateSearch([query?.start_date || null, query?.end_date || null]);
    }
    if (query?.press_release_type) {
      setCategory(query?.press_release_type);
    }
    if (query?.keyword) {
      setSearch(query?.keyword);
    }
  }, [location.search]);

  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            rowExpandedBg: "rgba(0,0,0,0)",
          },
        },
      }}
    >
      <Flex align='end' gap={24} className='mb-[24px] flex-wrap'>
        <PrRangeDatePicker
          label='기간'
          classLabel='!text-[#888896]'
          wrapperClassName={"h-[48px] min-w-[196px] max-w-[196px]"}
          startDate={dateSearch?.[0] || null}
          endDate={dateSearch?.[1] || null}
          onChange={(startDate, endDate) => {
            setDateSearch([startDate, endDate]);
          }}
          timeRequest={query?.time_request}
          className='!text-[14px]'
          rootClass={`pr-react-datepicker-filter`}
        />

        <PrSelect
          label='유형'
          placeholder='전체 유형'
          classLabel='!text-[#888896]'
          options={[...[{ value: null, label: "전체 유형" }, ...optionsPressRelease]]}
          rootClass='w-[196px] pr-select-input-custom'
          height='48px'
          value={category}
          onChange={(e) => {
            setCategory(e);
          }}
          itemClass='text-[14px] text-[#555560]'
        />
        <PrAutoComplete
          label={" "}
          placeholder='기사 제목 또는 키워드를 입력해 주세요'
          height='48px'
          value={search}
          className={"min-w-[415px]"}
          onChange={(e) => {
            listSearch(e);
            setSearch(e);
          }}
          options={articles}
          allowClear={true}
          debounceTime={300}
        />
        <div className='flex gap-x-[12px]'>
          <PrButton
            type='primary'
            height='48px'
            width='160px'
            onClick={() => {
              const queryNew = {
                ...query,
                keyword: search,
                start_date: dateSearch?.[0] ? formatDate(dateSearch[0], "YYYY-MM-DD") : null,
                end_date: dateSearch?.[1] ? formatDate(dateSearch[1], "YYYY-MM-DD") : null,
                page: 1,
                press_release_type: category,
              };
              setQueryParams(queryNew);
            }}
          >
            <SearchSvg />
            <div className='text-[16px] text-[#FFF] font-[500] leading-[24px]'>검색</div>
          </PrButton>
          <PrButton
            type='primary'
            height='48px'
            width='90px'
            defaultColor='#EFEFF5'
            onClick={() => {
              setSearch("");
              setCategory(null);
              clearQueryParams();
              setDateSearch(null);
            }}
          >
            <div className='text-[16px] !text-[#555560] font-[500] leading-[24px]'>초기화</div>
          </PrButton>
        </div>
      </Flex>
      <div className='flex flex-col gap-y-[16px]'>
        <div className='flex justify-end items-center'>
          <PrSelect
            value={query?.order_by || "DESC"}
            options={optionOrder}
            onChange={(e) => {
              setQueryParams({
                ...query,
                order_by: e,
              });
            }}
            rootClass='w-[112px]'
            height='40px'
            itemClass='text-[14px]'
          />
        </div>
        <Table
          loading={{
            spinning: loading,
            indicator: <img src={loadingGif} alt="Loading..."  className="!w-[50px] !h-[50px]"/>,
          }}
          columns={columns}
          dataSource={dataSource}
          rowKey='id'
          scroll={{ x: "max-content" }}
          onChange={handleTableChange}
          pagination={{
            total: pagination.total,
            pageSize: pagination.limit,
            current: pagination.page,
            position: ["bottomCenter"],
            showSizeChanger: false,
            nextIcon: <NextTableSvg />,
            prevIcon: <PrevTableSvg />,
          }}
          locale={{
            emptyText: (
              <div className='h-[368px] flex justify-center items-center'>
                작성된 보도자료가 없습니다.
              </div>
            ),
          }}
          expandable={{
            expandedRowRender: (record) => {
              return (
                <div className='px-1 pb-2'>
                  <ExpandTable record={record} />
                </div>
              );
            },
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <Button
                  type='primary'
                  className='py-1 px-3 h-fit text-sm leading-6 font-medium focus-visible:outline-none'
                  onClick={(e) => onExpand(record, e)}
                >
                  보기{" "}
                  <svg
                    width='17'
                    height='16'
                    viewBox='0 0 17 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M12.5 10L8.5 6L4.5 10'
                      stroke='white'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </Button>
              ) : (
                <Button
                  className='py-1 px-3 h-fit text-sm leading-6 font-medium'
                  onClick={(e) => onExpand(record, e)}
                >
                  보기{" "}
                  <svg
                    width='17'
                    height='16'
                    viewBox='0 0 17 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M12.5 6L8.5 10L4.5 6'
                      stroke='#888896'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </Button>
              ),
            onExpand: handleExpand,
            columnTitle: <p className='text-center'>분석</p>,
          }}
        />
      </div>
      <ModalDetail
        id={idDetail}
        isModalOpen={isOpenModal}
        onCancel={() => {
          setIsModalOpen(false);
          setIdDetail(null);
        }}
        refreshList={(message) => {
          getListData();
          openToast(message, "success");
        }}
      />
    </ConfigProvider>
  );
}

export default ResultAnalysis;
