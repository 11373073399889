import { FORMAT_DEFAULT_DATEPCIKER } from "contants/app.contant";
import dayjs from 'dayjs';

export const formatDate = (date,format=FORMAT_DEFAULT_DATEPCIKER) => {
    return date ? dayjs(date).format(format) : "";
};

export function formatDateRange(dateRange) {
    const [startDate, endDate] = dateRange.split('~');
    
    const formatDate = (dateStr) => {
      const [year, month, day] = dateStr.split('.');
      return `${year}년 ${parseInt(month, 10)}월 ${parseInt(day, 10)}일`;
    };
    
    const formattedStartDate = formatDate(startDate.trim());
    const formattedEndDate = formatDate(endDate.trim());
    
    return `${formattedStartDate} ~ ${formattedEndDate}`;
  }