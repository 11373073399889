export const API_VERSION = "v1";

export const BEARER_TOKEN_TYPES = {
  ACCESS_TOKEN: "access_token",
  REFRESH_TOKEN: "refresh_token",
};

export const APP_ENV = {
  DEVELOPMENT: "development",
  STAGING: "staging",
  PRODUCTION: "production",
  TEST: "test",
};

export const ORDER_BY = {
  ASC: "ASC",
  DESC: "DESC",
};

export const ACTIVE_FLAG = {
  IS_ACTIVE: 1,
  NOT_ACTIVE: 0,
};

export const DELETED_FLAG = {
  IS_DELETED: 1,
  NOT_DELETED: 0,
};

export const FETCH_FLAG = {
  ALL: 1,
  DEFAULT: 0,
};

export const HIDDEN_FLAG = {
  IS_HIDDEN: 1,
  NOT_HIDDEN: 0,
};

export const FORCE_UPDATE_FLAG = {
  FORCED: 1,
  NORMAL: 0,
};

export const SEEN_FLAG = {
  IS_SEEN: 1,
  NOT_SEEN: 0,
};

export const SHAREABLE_FLAG = {
  DENIED: 0,
  ALLOW: 1,
};

export const QUERY_COMPARISON_OPERATORS = {
  EQUAL: "eq",
  NOT: "not",
  LIKE: "like",
  GREATER_THAN: "gt",
  GREATER_THAN_OR_EQUAL: "gte",
  LESS_THAN: "lt",
  LESS_THAN_OR_EQUAL: "lte",
};

export const GENDER = {
  MALE: "male",
  FEMALE: "female",
  OTHER: "other",
};

export const INQUIRY_STATUS = {
  PENDING: "pending",
  IN_PROGRESS: "in_progress",
  COMPLETED: "completed",
};

export const INQUIRY_TYPE = {
  WRITE_PRESS_RELEASE: "write_press_release",
  ACCOUNT_MANAGEMENT: "account_management",
  PAYMENT_REFUND: "payment_refund",
  OTHER: "other",
};

export const ATTACHMENT_TYPE = {
  OTHER: "other",
  IMAGE: "image",
  VIDEO: "video",
  AUDIO: "audio",
  PDF: "pdf",
  DOCS: "docs",
  EXCEL: "excel",
  ZIP: "zip",
  EMBED: "embed",
};

export const USER_TYPE = {
  ADVERTISER: "advertiser",
  MEDIA: "media",
};

export const HELP_TYPE = {
  USAGE: "usage",
  FAQ: "faq",
};

export const API_TYPE = {
  NAVER_TREND: "naver_trend",
  NAVER_AD: "naver_ad",
  NAVER_AD_MANAGE_COOKIES: "naver_ad_manage_cookies",
};

export const API_METHOD = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
};

export const COMPANY_TYPE = {
  GENERAL_BUSINESS: "general_business",
  AGENCY: "agency",
};

export const COMPANY_LABEL = {
  [COMPANY_TYPE.GENERAL_BUSINESS]: "기업",
  [COMPANY_TYPE.AGENCY]: "대행사",
};

export const COMPANY_BG = {
  [COMPANY_TYPE.GENERAL_BUSINESS]: "#578FFC",
  [COMPANY_TYPE.AGENCY]: "#FD5252",
};

export const PRESS_RELEASE_TYPE = {
  PRODUCT: "product",
  CAMPAIGN: "campaign",
  PROMOTION: "promotion",
  INVESTMENT: "investment",
  CONTRACT: "contract",
  ARCHIEVEMENT: "achievement",
  SOCIAL: "social",
  TECHNOLOGY: "technology",
  CHARACTER: "character",
  POLICY: "policy",
  OTHER: "other",
};

export const PRESS_RELEASE_LABELS = {
  [PRESS_RELEASE_TYPE.PRODUCT]: "제품/서비스 출시",
  [PRESS_RELEASE_TYPE.CAMPAIGN]: "캠페인 론칭",
  [PRESS_RELEASE_TYPE.PROMOTION]: "프로모션/이벤트",
  [PRESS_RELEASE_TYPE.INVESTMENT]: "투자유치",
  [PRESS_RELEASE_TYPE.CONTRACT]: "계약 체결",
  [PRESS_RELEASE_TYPE.ARCHIEVEMENT]: "실적 및 성과",
  [PRESS_RELEASE_TYPE.SOCIAL]: "사회공헌 및 ESG 활동",
  [PRESS_RELEASE_TYPE.TECHNOLOGY]: "기술 혁신 및 연구 개발",
  [PRESS_RELEASE_TYPE.CHARACTER]: "인물 동정",
  [PRESS_RELEASE_TYPE.POLICY]: "정책 변경 및 업계 동향",
  [PRESS_RELEASE_TYPE.OTHER]: "기타",
};

export const OPTIONS_PRESS_RELEASE = [
  { value: PRESS_RELEASE_TYPE.PRODUCT, label: "제품/서비스 출시" },
  { value: PRESS_RELEASE_TYPE.CAMPAIGN, label: "캠페인 론칭" },
  { value: PRESS_RELEASE_TYPE.PROMOTION, label: "프로모션/이벤트" },
  { value: PRESS_RELEASE_TYPE.INVESTMENT, label: "투자유치" },
  { value: PRESS_RELEASE_TYPE.CONTRACT, label: "계약 체결" },
  { value: PRESS_RELEASE_TYPE.ARCHIEVEMENT, label: "실적 및 성과" },
  { value: PRESS_RELEASE_TYPE.SOCIAL, label: "사회공헌 및 ESG 활동" },
  { value: PRESS_RELEASE_TYPE.TECHNOLOGY, label: "기술 혁신 및 연구 개발" },
  { value: PRESS_RELEASE_TYPE.CHARACTER, label: "인물 동정" },
  { value: PRESS_RELEASE_TYPE.POLICY, label: "정책 변경 및 업계 동향" },
  { value: PRESS_RELEASE_TYPE.OTHER, label: "기타" },
];

export const PRESS_RELEASE_STATUS = {
  DRAFT: "draft",
  REVIEWING: "reviewing",
  REVIEW_COMPLETED: "review_completed",
  REVIEW_REJECTED: "review_rejected",
  REJECTED: "rejected",
  APPROVED: "approved",
  PUBLISHED: "published",
};

export const PRESS_RELEASE_STATUS_LABEL = {
  [PRESS_RELEASE_STATUS.DRAFT]: "임시저장",
  [PRESS_RELEASE_STATUS.REVIEWING]: "검수 중",
  [PRESS_RELEASE_STATUS.REVIEW_COMPLETED]: "검수 완료",
  [PRESS_RELEASE_STATUS.REVIEW_REJECTED]: "반려",
  [PRESS_RELEASE_STATUS.REJECTED]: "거절",
  [PRESS_RELEASE_STATUS.APPROVED]: "승인",
  [PRESS_RELEASE_STATUS.PUBLISHED]: "배포 완료",
};

export const PRESS_RELEASE_STATUS_COLOR = {
  [PRESS_RELEASE_STATUS.DRAFT]: "#888896",
  [PRESS_RELEASE_STATUS.REVIEWING]: "#555560",
  [PRESS_RELEASE_STATUS.REVIEW_COMPLETED]: "#FB9600",
  [PRESS_RELEASE_STATUS.REVIEW_REJECTED]: "#F070C5",
  [PRESS_RELEASE_STATUS.REJECTED]: "#FD5252",
  [PRESS_RELEASE_STATUS.APPROVED]: "#00B22B",
  [PRESS_RELEASE_STATUS.PUBLISHED]: "#578FFC",
};

export const PRESS_RELEASE_STATUS_BG_COLOR = {
  [PRESS_RELEASE_STATUS.DRAFT]: "",
  [PRESS_RELEASE_STATUS.REVIEWING]: "#EFEFF5",
  [PRESS_RELEASE_STATUS.REVIEW_COMPLETED]: "#FFF6E4",
  [PRESS_RELEASE_STATUS.REVIEW_REJECTED]: "#FDF1F9",
  [PRESS_RELEASE_STATUS.REJECTED]: "#FEEFEF",
  [PRESS_RELEASE_STATUS.APPROVED]: "#E7FBEC",
  [PRESS_RELEASE_STATUS.PUBLISHED]: "#EDF5FF",
};

export const WRITE_PRESS = {
  MANUAL: "manual",
  DRAFT: "draft",
  REQUEST: "request",
};

export const WRITE_PRESS_LABEL = {
  [WRITE_PRESS.MANUAL]: "직접작성",
  [WRITE_PRESS.DRAFT]: "임시저장",
  [WRITE_PRESS.REQUEST]: "작성 대행 요청",
};

export const WRITE_PRESS_COLOR = {
  [WRITE_PRESS.MANUAL]: "#FD5252",
  [WRITE_PRESS.DRAFT]: "#555560",
  [WRITE_PRESS.REQUEST]: "#578FFC",
};

export const WRITE_PRESS_BG = {
  [WRITE_PRESS.MANUAL]: "#FEEFEF",
  [WRITE_PRESS.DRAFT]: "#EFEFF5",
  [WRITE_PRESS.REQUEST]: "#EDF5FF",
};

export const PUBLISH_STATUS = {
  PEDNING: "pending",
  REVIEWING: "reviewing",
  APPROVED: "approved",
  REJECTED: "rejected",
  PUBLISHED: "published",
};

export const PUBLISH_STATUS_LABEL = {
  [PUBLISH_STATUS.APPROVED]: "승인",
  [PUBLISH_STATUS.PUBLISHED]: "배포 완료",
  [PUBLISH_STATUS.REJECTED]: "거절",
};

export const PUBLISH_STATUS_COLOR = {
  [PUBLISH_STATUS.APPROVED]: "#00B22B",
  [PUBLISH_STATUS.PUBLISHED]: "#578FFC",
  [PUBLISH_STATUS.REJECTED]: "#EC2929",
};

export const PRESS_RELEASE_TAB = {
  ALL: "all",
  IN_PROGRESS: "in_progress",
  PUBLISHED: "published",
  DRAFT: "draft",
};

export const SEARCH_PLATFORM = {
  GOOGLE: "google",
  NAVER: "naver",
  DAUM: "daum",
  ETC: "etc",
};

export const FORMAT_DEFAULT_DATEPCIKER = "YYYY.MM.DD";
export const FORMAT_DEFAULT_REACT_DATEPCIKER = "yyyy.MM.dd";
export const FORMAT_DATE_TIME_PICKER = "YY.MM.DD A H:mm";

export const THEME = {
  HEADER_HEIGHT: 80,
  FOOTER_HEIGHT: 80,
  SIDEBAR_WIDTH: 104,
};

export const AUTHENTICATED_ENTRY = "/advertiser/press-release";
export const AUTHENTICATED_MEDIA_ENTRY = "/media/press-release";

export const MODE = {
  REWRITE: "rewrite",
};

export const CHART_TYPE = {
  AGE: "age",
  GENDER: "gender",
  REFERRER: "referrer",
};
