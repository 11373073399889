import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
//
import { authResources } from "./dictionaries/auth/i18n";
import { generalResources } from "./dictionaries/general/i18n";

const deepMerge = (target, source) => {
  for (const key of Object.keys(source)) {
    if (source[key] instanceof Object && key in target) {
      Object.assign(source[key], deepMerge(target[key], source[key]));
    }
  }
  return { ...target, ...source };
};

const mergeResources = (modules) => {
  return modules.reduce(
    (acc, module) => {
      return {
        ko: deepMerge(acc.ko || {}, module.ko),
        en: deepMerge(acc.en || {}, module.en),
      };
    },
    { ko: {}, en: {} }
  );
};

const modules = [
  generalResources,
  authResources,
];
const resources = mergeResources(modules);

const language = localStorage.getItem("I18N_LANGUAGE");
if (!language) {
  localStorage.setItem("I18N_LANGUAGE", "ko");
}

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem("I18N_LANGUAGE") || "ko",
    fallbackLng: "ko", // use en if detected lng is not available

    // keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
