import { useLocation, useNavigate } from 'react-router-dom';

/**
 * @typedef {Object<string, string | number | boolean | null | undefined>} QueryParams
 */

/**
 * Custom hook for managing query parameters in the URL.
 * @param {QueryParams} [defaultParams={}] - Default query parameters to apply if they don't exist in the URL.
 * @returns {{
 *   getQueryParams: () => QueryParams,
 *   setQueryParams: (params: QueryParams) => void,
 *   clearQueryParams: () => void
 * }} - Methods for interacting with query parameters.
 */
const useQueryParams = (defaultParams = {}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const parseValue = (value) => {
    if (value === '') return '';
    if (value === 'true') return true;
    if (value === 'false') return false;
    if (!isNaN(Number(value))) return Number(value);
    return value;
  };

  const getQueryParams = () => {
    const searchParams = new URLSearchParams(location.search);

    // Apply default parameters if they don't exist in the URL
    Object.keys(defaultParams).forEach((key) => {
      if (!searchParams.has(key)) {
        searchParams.set(key, String(defaultParams[key]));
      }
    });

    // Convert URLSearchParams to a plain object with correct types
    const params = {};
    searchParams.forEach((value, key) => {
      params[key] = parseValue(value);
    });

    return params;
  };

  const setQueryParams = (params) => {
    const searchParams = new URLSearchParams(location.search);

    Object.keys(params).forEach((key) => {
      if (params[key] !== undefined && params[key] !== null) {
        searchParams.set(key, String(params[key]));
      } else {
        searchParams.delete(key);
      }
    });

    searchParams.set('time_request', String(Date.now()));

    navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
  };

  const clearQueryParams = () => {
    navigate(location.pathname, { replace: true });
  };

  return {
    getQueryParams,
    setQueryParams,
    clearQueryParams,
  };
};

export default useQueryParams;
