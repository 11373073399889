export const catchAsync = async (p, setLoading) => {
  const isPromise = (p) =>
    typeof p === "object" && typeof p.then === "function";

  if (!isPromise(p)) {
    throw new Error("Require promise");
  }

  if (setLoading) setLoading(true);
  try {
    const result = await p;
    if (setLoading) setLoading(false);
    return [null, result];
  } catch (err) {
    if (setLoading) setLoading(false);
    return [err];
  }
};
