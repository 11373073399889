import React, { useEffect, useMemo, useState } from "react";
import { Tooltip, AutoComplete } from "antd";
import debounce from "lodash/debounce";
//
import RenderIfComponent from "components/RenderIfComponent";
import { ReactComponent as RequireSvg } from "asset/svg/require.svg";
import { ReactComponent as HelpSvg } from "asset/svg/help.svg";

const PrAutoComplete = ({
  defaultValue,
  placeholder = "Enter text",
  onChange,
  allowClear = false,
  label,
  subLabel,
  rootClass = "",
  required = false,
  tooltipContent,
  value,
  maxLength,
  disabled = false,
  prefix,
  suffix,
  type = "text",
  error,
  className,
  height = "56px",
  classLabel,
  options = [], // Array of options for autocomplete
  filterOption = true, // Enable/disable filtering options
  debounceTime = 300, // New option to pass debounce time
}) => {
  const [inputValue, setInputValue] = useState(value);

  const debouncedOnChange = useMemo(
    () => debounce(onChange, debounceTime),
    [onChange, debounceTime]
  );

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleInputChange = (e) => {
    const newValue = e;
    setInputValue(newValue);
    debouncedOnChange(newValue);
  };

  const renderTooltip = (content) => <div className="p-[16px]">{content}</div>;

  const highlightText = (text, search) => {
    if (!search) return text;
    const regex = new RegExp(`(${search})`, "gi");
    return text?.split(regex)?.map((part, index) =>
    part?.toLowerCase() === search?.toString()?.toLowerCase() ? (
        <span key={index} style={{ fontWeight: "600", color: "#2C2C33" }}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  const renderOption = (option) => {
    return {
      value: option.value,
      label: (
        <div>
          {highlightText(option?.label, inputValue)}
        </div>
      ),
    };
  };

  return (
    <div className={`pr-autocomplete flex flex-col gap-y-[8px] ${rootClass}`}>
      <div>
        <RenderIfComponent isValid={!!label}>
          <div className="flex items-center">
          <label className={`text-[18px] font-[600] text-[#2C2C33] ${classLabel ? classLabel : ""}`}>
              {label}
            </label>
            {required && <RequireSvg />}
            <RenderIfComponent isValid={!!tooltipContent}>
              <Tooltip
                rootClassName="p-[0px]"
                placement="bottom"
                title={renderTooltip(tooltipContent)}
              >
                <HelpSvg className="ml-[8px] cursor-pointer" />
              </Tooltip>
            </RenderIfComponent>
          </div>
        </RenderIfComponent>
        {!!subLabel?.length && (
          <span className="text-[14px] text-[#888896] font-[400]">
            {subLabel}
          </span>
        )}
      </div>
      <AutoComplete
        value={inputValue}
        options={options.map(renderOption)}
        filterOption={filterOption}
        defaultValue={defaultValue}
        placeholder={placeholder}
        onChange={handleInputChange}
        allowClear={allowClear}
        className={`w-full ${className ? className : ""}`}
        maxLength={maxLength}
        disabled={disabled}
        prefix={prefix}
        suffix={suffix}
        type={type}
        status={!!error ? "error" : undefined}
        style={{
          height,
        }}
      ></AutoComplete>
    </div>
  );
};

export default PrAutoComplete;
