import React, { useState } from "react";
import { Input, Tooltip } from "antd";
//
import RenderIfComponent from "components/RenderIfComponent";
import { ReactComponent as RequireSvg } from "asset/svg/require.svg";
import { ReactComponent as HelpSvg } from "asset/svg/help.svg";

const PrTextarea = ({
  defaultValue,
  placeholder = "Enter text",
  onChange,
  allowClear = false,
  label,
  rootClass,
  required = false,
  tooltipContent,
  value,
  maxLength=500,
  minHeight = 160,
  disabled = false,
  autoSize = false,
  align = false,
  subLabel,
  error,
  readonly,
  showCount,
  classLabel
}) => {
  const [focus, setFocus] = useState(false);
  const renderTooltip = (children) => {
    return <div>{children}</div>;
  };

  return (
    <div className={`pr-textarea flex flex-col gap-y-[8px] ${rootClass ? rootClass : ""}`}>
      <RenderIfComponent isValid={!!label || !!subLabel}>
        <div>
          <RenderIfComponent isValid={!!label}>
            <div className="flex items-center">
              <label className={`text-[18px] font-[600] text-[#2C2C33] ${classLabel ? classLabel : ""}`}>
                {label}
              </label>
              {required && <RequireSvg />}
              <RenderIfComponent isValid={!!tooltipContent}>
                <Tooltip
                  rootClassName="p-[0px]"
                  placement="bottom"
                  title={renderTooltip(tooltipContent)}
                  align={align}
                >
                  <HelpSvg className="ml-[8px] cursor-pointer" />
                </Tooltip>
              </RenderIfComponent>
            </div>
          </RenderIfComponent>
          {!!subLabel?.length && (
            <span className="text-[14px] text-[#888896] font-[400]">
              {subLabel}
            </span>
          )}
        </div>
      </RenderIfComponent>
      <div className={`border ${focus ? "border-[#2C2C33]" : "border-[#E0E0E0]"} hover:border-[#2C2C33] rounded-[12px] pb-[16px] pt-[16px]`}>
        <Input.TextArea
          value={value}
          defaultValue={defaultValue}
          placeholder={placeholder}
          onChange={onChange}
          allowClear={allowClear}
          className={`w-full px-[16px]`}
          maxLength={maxLength}
          styles={{
            textarea: {
              minHeight: `${minHeight - 16 - (showCount ? 24 : 0)}px`,
              border: "none",
            },
          }}
          readOnly={readonly}
          style={{ resize: "none" }}
          disabled={disabled}
          autoSize={autoSize}
          status={error ? "error" : null}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />
        {showCount && (
          <div className="text-[14px] text-[#888896] text-right px-[16px]">
            {value?.length || 0}/{maxLength}
          </div>
        )}
      </div>
    </div>
  );
};

export default PrTextarea;
