import { createAsyncThunk } from "@reduxjs/toolkit";
import authApi from "../../services/auth";

//Include Both Helper File with needed methods
// import categoryApi from "api/categoryApi";

export const login = createAsyncThunk("auth", async (values) => {
  try {
    const response = await authApi.login(values);
    return response;
  } catch (error) {
    return error;
  }
});

export const getProfile = createAsyncThunk("auth", async (values) => {
  try {
    const response = await authApi.getProfile(values);
    return response;
  } catch (error) {
    return error;
  }
});