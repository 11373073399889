import React, { useEffect, Suspense, lazy } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import { useDispatch } from "react-redux";
//
import { getConfigs } from "store/thunks";
import { ProtectedRoute } from "./ProtectedRoute";
import { AUTHENTICATED_ENTRY, AUTHENTICATED_MEDIA_ENTRY } from "contants/app.contant";
import helper from "helper";
import ResultAnalysis from "page/resultAnalysis";

// Dynamic imports for components
const Login = lazy(() => import("auth/Login"));
const Register = lazy(() => import("auth/Register"));
const FindEmail = lazy(() => import("auth/FindEmail"));
const WritePressRelease = lazy(() => import("page/writePressRelease"));
const Inquiry = lazy(() => import("page/inquiry/index.js"));
const Profile = lazy(() => import("auth/Profile"));
const ResetPassword = lazy(() => import("auth/ResetPassword"));
const Advertiser = lazy(() => import("page/advertiser"));
const MediaPressReleaes = lazy(() => import("page/mediaPressReleaes"));

const AppRouter = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getConfigs());
  }, [dispatch]);

  // Define your routes
  const routes = [
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/register",
      element: <Register />,
    },
    {
      path: "/find-email",
      element: <FindEmail />,
    },
    {
      path: "/forgot-password",
      element: <FindEmail type="password" />,
    },
    {
      path: "/reset-password",
      element: <ResetPassword />,
    },
    {
      path: "/",
      element: (
        <ProtectedRoute>
          <Navigate replace to={helper.isUserAdvertiser() ? AUTHENTICATED_ENTRY : AUTHENTICATED_MEDIA_ENTRY} />
        </ProtectedRoute>
      ),
    },
    {
      path: "/advertiser/press-release",
      element: (
        <ProtectedRoute>
          <Advertiser />
        </ProtectedRoute>
      ),
    },
    {
      path: "/advertiser/press-release/template",
      element: (
        <ProtectedRoute>
          <WritePressRelease />
        </ProtectedRoute>
      ),
    },
    {
      path: "/advertiser/press-release/template/:id",
      element: (
        <ProtectedRoute>
          <WritePressRelease />
        </ProtectedRoute>
      ),
    },
    {
      path: "/advertiser/press-release/manual",
      element: (
        <ProtectedRoute>
          <WritePressRelease />
        </ProtectedRoute>
      ),
    },
    {
      path: "/advertiser/press-release/manual/:id",
      element: (
        <ProtectedRoute>
          <WritePressRelease />
        </ProtectedRoute>
      ),
    },
    {
      path: "/media/press-release",
      element: (
        <ProtectedRoute>
          <MediaPressReleaes />
        </ProtectedRoute>
      ),
    },
    {
      path: "/inquiry",
      element: (
        <ProtectedRoute>
          <Inquiry />
        </ProtectedRoute>
      ),
    },
    {
      path: "/adjustment",
      element: (
        <ProtectedRoute>
          In-progress
        </ProtectedRoute>
      ),
    },
    {
      path: "/profile",
      element: (
        <ProtectedRoute>
          <Profile />
        </ProtectedRoute>
      ),
    },
    {
      path: "/analysis",
      element: (
        <ProtectedRoute>
          <ResultAnalysis />
        </ProtectedRoute>
      ),
    },
  ];

  return <Suspense>{useRoutes(routes)}</Suspense>;
};

export default AppRouter;
