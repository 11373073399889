import { Modal } from "antd";
import { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import PrSingleRangePicker from "components/PrSingleRangePicker";
//
import { ReactComponent as CloseSvg } from "asset/svg/closeModal.svg";
import { ReactComponent as InfoSvg } from "asset/svg/info.svg";
import PrButton from "components/PrButton";
import PrSelect from "components/PrSelect";
import PrSelectCheckbox from "components/PrSelectCheckbox";
import PrTextarea from "components/PrTextarea";
import PrInputTags from "components/PrInputTags";
import PrUpload from "components/PrUpload";
import { catchAsync } from "helper/api.helper";
import advertiserApi from "services/advertiser";
import RenderIfComponent from "components/RenderIfComponent";
import {
  OPTIONS_PRESS_RELEASE,
  PRESS_RELEASE_STATUS,
  PRESS_RELEASE_TYPE,
  WRITE_PRESS,
  WRITE_PRESS_BG,
  WRITE_PRESS_COLOR,
  WRITE_PRESS_LABEL,
} from "contants/app.contant";
import PrDatePicker from "components/PrDatePicker";
import { ReactComponent as DotSvg } from "asset/svg/dot.svg";
import { removeEmptyFields } from "helper";
import { useToast } from "context/toast.context";
import { useConfirm } from "context/confirm.context";
import { useNavigate } from "react-router-dom";
import PrDivinput from "components/PrDivInput";
import PrLoading from "components/PrLoading";

const ModalDetail = ({ id, isModalOpen, media, onCancel, refreshList }) => {
  const modalBodyRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const optionsPressRelease = OPTIONS_PRESS_RELEASE;
  const industries = useSelector(
    (state) => state?.Systems?.configs?.industries
  );
  const { openToast } = useToast();
  const { showConfirm } = useConfirm();
  const navigate = useNavigate();

  const renderTooltipCompany = () => {
    const itemRenders = [
      {
        label: "이노바티브 테크놀로지스",
        description: (
          <>
            <div>이노바티브 테크놀로지스는 첨단 소프트웨어 개발과</div>
            <div>디지털 혁신을 선도하는 기업이다.</div>
            <div>
              인공지능, 빅데이터, 그리고 IoT 솔루션을 제공하며, 글로벌 시장에서
            </div>
            <div>
              경쟁력을 강화하기 위해 다양한 산업에 맞춤형 소프트웨어를 개발한다.
            </div>
            <div>기업 고객의 디지털 전환을 지원하는 파트너로 활동한다.</div>
          </>
        ),
      },
      {
        label: "에코솔루션즈",
        description: (
          <>
            <div>
              에코솔루션즈는 환경 보호와 지속 가능한 기술 개발에 집중하는
              기업이다.
            </div>
            <div>
              재생 가능 에너지 및 에코 프렌들리 제품을 개발하고 제공한다.
            </div>
            <div>
              세계 여러 국가의 파트너십을 통해 지속 가능한 미래를 만들기 위한
              혁신적인
            </div>
            <div>솔루션을 개발한다.</div>
          </>
        ),
      },
      {
        label: "퓨처스트림 네트웍스",
        description: (
          <>
            <div>
              퓨처스트림 네트웍스는 차세대 통신 기술을 개발하는 선도적인
              기업이다.
            </div>
            <div>
              5G, 고속 인터넷 인프라, 그리고 스마트 시티 솔루션을 제공한다.
            </div>
            <div>
              기술의 진보를 이끌며, 글로벌 시장에서 혁신적인 네트워크 솔루션을
              제공한
            </div>
            <div>다.</div>
          </>
        ),
      },
    ];

    return (
      <div className="flex flex-col gap-y-[8px] w-[468px] h-[478px] p-[16px]">
        <div className="text-[18px] font-[600] italic leading-[26px]">
          회사 소개 작성 샘플
        </div>
        {itemRenders.map((item, index) => (
          <div className="text-[14px] whitespace-nowrap" key={index}>
            <div className="text-[#FFF] font-[400] italic leading-[24px]">
              {index + 1}. {item.label}
            </div>
            <div className="text-[#E1E1EB] font-[400] mt-[4px] leading-[24px]">
              {item.description}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderTooltip = () => {
    const itemRenders = [
      {
        label: "제품/서비스 출시",
        description: "새 제품이나 서비스를 시장에 도입하는 것을 알림",
      },
      {
        label: "캠페인 론칭",
        description: "특정 마케팅 캠페인이나 이니셔티브를 시작하는 것을 발표",
      },
      {
        label: "프로모션/이벤트",
        description: "특별 판매 활동이나 이벤트를 개최하여 공중의 참여를 유도",
      },
      {
        label: "투자유치",
        description: "기업이 자금을 유치했거나 투자받았음을 공개",
      },
      {
        label: "계약 체결",
        description: "중요한 비즈니스 계약이나 파트너십을 체결했다는 소식",
      },
      {
        label: "실적 및 성과",
        description: "회사의 경영 성과나 중요 실적을 발표",
      },
      {
        label: "사회공헌 및 ESG 활동",
        description: "기업의 사회적 책임과 환경, 사회, 거버넌스 관련 활동 보도",
      },
      {
        label: "기술 혁신 및 연구개발",
        description: "신기술 개발이나 연구 활동의 결과를 공유",
      },
      {
        label: "인물 동정",
        description: "회사 임직원이나 관계자의 중요 활동 및 업적",
      },
      {
        label: "정책 변경 및 업계 동향",
        description: "업계의 중요 변화나 정책 업데이트 소식",
      },
    ];
    return (
      <div className="flex flex-col gap-y-[8px]">
        <div className="text-[18px] font-[600] italic">보도자료 유형</div>
        <div className="flex flex-col gap-y-[8px]">
          {itemRenders.map((item, index) => (
            <div
              className="flex gap-x-[4px] text-[14px] whitespace-nowrap items-center"
              key={index}
            >
              <DotSvg />
              <div className="text-[#FFF] font-[400] italic">{item.label}</div>
              <div className="text-[#E1E1EB] font-[400]">
                {item.description}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const handleCancel = () => {
    if (modalBodyRef.current) {
      modalBodyRef.current.scrollTop = 0;
    }
    onCancel();
  };

  const renderValue = (values) => {
    let result = "-";
    if (values) {
      result = [
        values.press_release_type === PRESS_RELEASE_TYPE.PRODUCT
          ? values?.name
          : "-",
        values?.email || "-",
        values?.company_website_url || "-",
      ].join(" / ");
    }
    return result;
  };

  const formik = useFormik({
    initialValues: {},
  });

  const { values, setValues } = formik;

  const getDetail = async () => {
    const [err, result] = await catchAsync(
      advertiserApi.getDetailtAdvertiserPressReleases(id),setLoading
    );
    if (err) {
      openToast(err?.message, "error");
      return;
    }
    if (result?.data?.is_manual) {
    }
    const params = removeEmptyFields(result?.data);
    if (params?.publishers?.length) {
      params.media_ids = params?.publishers?.map((item) => item?.media_id);
    }
    if (
      ![PRESS_RELEASE_TYPE.CAMPAIGN, PRESS_RELEASE_TYPE.PROMOTION].includes(
        params?.press_release_type
      ) &&
      params?.date?.split("~")?.length > 1
    ) {
      const { date, ...newParams } = params;
      setValues(newParams);
      return;
    }
    setValues(params);
  };

  const cancelAdvertiserPressRelease = async () => {
    const [err, _] = await catchAsync(
      advertiserApi.cancelAdvertiserPressRelease(id)
    );
    if (err) {
      openToast(err?.message, "error");
      return false;
    }
    handleCancel();
    refreshList("보도자료 전송을 취소하고, 해당 보도자료를 임시 저장했습니다.");
    return true;
  };

  const deleteAdvertiserPressRelease = async () => {
    const [err, _] = await catchAsync(
      advertiserApi.deleteAdvertiserPressRelease(id)
    );
    if (err) {
      openToast(err?.message, "error");
      return false;
    }
    refreshList("보도자료가 삭제되었습니다.");
    handleCancel();
    return true;
  };

  useEffect(() => {
    id && getDetail(id);
  }, [id]);

  useEffect(() => {
    if (!isModalOpen) {
      formik.resetForm();
    }
  }, [isModalOpen]);

  return (
    <Modal
      open={isModalOpen}
      onCancel={handleCancel}
      footer={null}
      centered
      closable={false}
      width={856}
      styles={{
        content: {
          borderRadius: "24px",
          padding: "42px 40px 14px 40px",
        },
      }}
    >
      <div className="relative">
        <RenderIfComponent isValid={loading}>
          <div
            className="absolute top-[50%] left-[50%] z-[1000] w-full h-full flex items-center justify-center"
            style={{
              transform: "translate(-50%, -50%)",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
            }}
          >
            <PrLoading />
          </div>
        </RenderIfComponent>
      <div className="flex justify-between items-center">
        <div className="flex gap-x-[10px] items-center">
          <div className="text-[32px] font-[700] text-[#000]">보도자료</div>
          <div
            className="text-[16px] font-[400] px-[8px] py-[4px] flex items-center justify-center rounded-[8px] max-h-[32px]"
            style={{
              backgroundColor:
                values?.status === PRESS_RELEASE_STATUS.DRAFT
                  ? WRITE_PRESS_BG[WRITE_PRESS.DRAFT]
                  : WRITE_PRESS_BG[
                      values.is_manual
                        ? WRITE_PRESS.MANUAL
                        : WRITE_PRESS.REQUEST
                    ],
              color:
                values?.status === PRESS_RELEASE_STATUS.DRAFT
                  ? WRITE_PRESS_COLOR[WRITE_PRESS.DRAFT]
                  : WRITE_PRESS_COLOR[
                      values.is_manual
                        ? WRITE_PRESS.MANUAL
                        : WRITE_PRESS.REQUEST
                    ],
            }}
          >
            {values?.status === PRESS_RELEASE_STATUS.DRAFT
              ? WRITE_PRESS_LABEL[WRITE_PRESS.DRAFT]
              : WRITE_PRESS_LABEL[
                  values.is_manual ? WRITE_PRESS.MANUAL : WRITE_PRESS.REQUEST
                ]}
          </div>
        </div>
        <div className="flex items-center">
          <RenderIfComponent
            isValid={values?.status === PRESS_RELEASE_STATUS.REVIEWING}
          >
            <div className="mr-[8px] text-[13px] font-[400] text-[#888896] px-[12px] py-[8px] flex items-center justify-center gap-x-[8px] bg-[#F9F9FB] rounded-[8px]">
              <InfoSvg /> 편집을 원하시면, 전송 취소 후 다시 작성해주세요
            </div>
            <PrButton
              type="primary"
              htmlType={"button"}
              label="전송취소"
              width="136px"
              height="48px"
              onClick={() => {
                showConfirm(
                  {
                    title: "보도자료 전송 취소",
                    message: (
                      <div className="text-center">
                        <span className="text-[#555560] text-[16px] font-[400] text-center">
                          이미 전송된 보도자료의 전송을 취소할까요?
                        </span>
                        <br />
                        <span className="text-[#888896] text-center text-[14px]">
                          *전송 취소한 보도자료는 임시저장됩니다.
                        </span>
                      </div>
                    ),
                  },
                  () => {
                    return cancelAdvertiserPressRelease();
                  }
                );
              }}
            />
          </RenderIfComponent>
          <RenderIfComponent
            isValid={values?.status === PRESS_RELEASE_STATUS.DRAFT}
          >
            <div className="flex items-center justify-center gap-x-[12px]">
              <PrButton
                type="primary"
                width="136px"
                height="48px"
                defaultColor="#EFEFF5"
                onClick={() => {
                  showConfirm(
                    {
                      title: "보도자료 삭제",
                      message: (
                        <div className="text-center">
                          <span className="text-[#555560] text-[16px] font-[400] text-center">
                            이 보도자료를 정말 삭제할까요?
                          </span>
                          <br />
                          <span className="text-[#888896] text-center text-[14px]">
                            *삭제한 보도자료는 복구할 수 없습니다.
                          </span>
                        </div>
                      ),
                    },
                    () => {
                      return deleteAdvertiserPressRelease();
                    }
                  );
                }}
              >
                <span className="text-[#555560] text-[16px] font-[500]">
                  {"삭제"}
                </span>
              </PrButton>
              <PrButton
                type="primary"
                width="136px"
                height="48px"
                label={"편집"}
                onClick={() => {
                  navigate(
                    `/advertiser/press-release/${
                      !values?.is_manual ? "template" : "manual"
                    }/${id}`
                  );
                }}
              />
            </div>
          </RenderIfComponent>
          <CloseSvg
            className="cursor-pointer ml-[40px]"
            onClick={handleCancel}
          />
        </div>
      </div>
      <form
        ref={modalBodyRef}
        className="flex flex-col gap-y-[24px] overflow-y-auto h-[calc(100vh-160px)] mr-[-32px] pb-[2px]"
      >
        <div className="flex flex-col gap-y-[32px] mt-[16px] mr-[32px]">
          <RenderIfComponent isValid={values.is_manual}>
            <PrDivinput
              label="제목"
              placeholder="보도자료의 제목을 입력하세요"
              required
              value={values.title}
              readonly
            />
          </RenderIfComponent>
          <PrSelect
            placeholder="제품/서비스 출시"
            label="보도자료 유형"
            options={optionsPressRelease}
            tooltipContent={renderTooltip()}
            required
            value={values.press_release_type}
            rootClass="w-full lg:w-2/3 xl:w-2/3"
            readonly
            isSuffixIcon={false}
          />
          <PrSelect
            label="카테고리"
            placeholder="카테고리 선택"
            options={industries}
            required
            keyValue="id"
            keyLabel="name"
            rootClass="w-full lg:w-2/3 xl:w-2/3"
            value={values.industry_id}
            readonly
            isSuffixIcon={false}
          />
          <PrSelectCheckbox
            label="매체"
            placeholder="매체 선택"
            options={values.publishers || []}
            required
            keyValue="media_id"
            keyLabel="media_name"
            rootClass="w-full"
            readonly
            isHiddenAction
          />
          <RenderIfComponent isValid={!values.is_manual}>
            <PrDivinput
              label="제목"
              placeholder="보도자료의 제목을 입력하세요"
              required
              value={values.title}
              readonly
            />
            <RenderIfComponent
              isValid={
                ![
                  PRESS_RELEASE_TYPE.CHARACTER,
                  PRESS_RELEASE_TYPE.POLICY,
                ].includes(values.press_release_type)
              }
            >
              <PrDivinput
                label="회사명"
                subLabel="기업이 여러개일 경우, 콤마(,)로 분리하여 작성"
                placeholder="보도자료의 내용과 관련된 회사의 이름을 작성하세요"
                required
                value={values.company_name}
                readonly
              />
            </RenderIfComponent>
            <RenderIfComponent
              isValid={[PRESS_RELEASE_TYPE.CHARACTER].includes(
                values.press_release_type
              )}
            >
              <PrDivinput
                label="인물 이름"
                placeholder="보도자료의 내용과 관련된 회사의 이름을 작성하세요"
                required
                value={values.person_name}
                rootClass="w-1/2"
                readonly
              />
            </RenderIfComponent>
            <RenderIfComponent
              isValid={[PRESS_RELEASE_TYPE.POLICY].includes(
                values.press_release_type
              )}
            >
              <PrTextarea
                label="정책 변경 내용"
                placeholder="내용을 입력하세요"
                required
                value={values.policy_name}
                readonly
              />
            </RenderIfComponent>

            {/* Start Product */}
            <RenderIfComponent
              isValid={[PRESS_RELEASE_TYPE.PRODUCT].includes(
                values.press_release_type
              )}
            >
              <div className="flex gap-x-[16px] w-full">
                <PrDivinput
                  label="제품/서비스명"
                  placeholder="제품 또는 서비스명을 입력하세요"
                  rootClass="w-1/2"
                  required
                  readonly
                  value={values.product_service_name}
                />
                <PrDatePicker
                  label="출시일자"
                  placeholder="제품 또는 서비스의 출시 날짜를 입력하세요 (ex. 2024.04.18)"
                  rootClass="w-1/2"
                  required
                  format="yyyy년 M월 dd일"
                  value={values.date}
                  readonly
                />
              </div>
              <PrTextarea
                label="제품/서비스 설명"
                placeholder="내용을 입력하세요"
                rootClass="w-full"
                required
                value={values.product_service_description}
                readonly
              />
              <PrTextarea
                label="제품/서비스 강점 및 장점"
                placeholder="내용을 입력하세요"
                rootClass="w-full"
                required
                value={values.strength_advantage}
                readonly
              />
            </RenderIfComponent>
            {/* End Product */}

            {/* Start campaign */}
            <RenderIfComponent
              isValid={[PRESS_RELEASE_TYPE.CAMPAIGN].includes(
                values.press_release_type
              )}
            >
              <div className="flex gap-x-[16px] w-full">
                <PrDivinput
                  label="브랜드명"
                  placeholder="브랜드명을 입력하세요"
                  rootClass="w-1/2"
                  required
                  value={values.brand_name}
                  readonly
                />
                <PrSingleRangePicker
                  label="공개 일자"
                  placeholder="캠페인 론칭 날짜 또는 기간을 입력하세요 (ex. 2024.04.18~2024.05.18)"
                  rootClass="w-1/2"
                  required
                  readonly
                  format="yyyy년 M월 dd일"
                  startDate={
                    [PRESS_RELEASE_TYPE.CAMPAIGN].includes(
                      values.press_release_type
                    ) &&
                    values?.date &&
                    values?.date?.split("~")?.[0]
                      ? new Date(values?.date?.split("~")?.[0])
                      : null
                  }
                  endDate={
                    [PRESS_RELEASE_TYPE.CAMPAIGN].includes(
                      values.press_release_type
                    ) &&
                    values?.date &&
                    values?.date?.split("~")?.[1]
                      ? new Date(values?.date?.split("~")?.[1])
                      : null
                  }
                />
              </div>
              <PrTextarea
                label="캠페인 명"
                placeholder="내용을 입력하세요"
                rootClass="w-full"
                required
                value={values.campaign_name}
                readonly
              />
              <PrTextarea
                label="캠페인 설명 및 목적"
                placeholder="내용을 입력하세요"
                rootClass="w-full"
                required
                value={values.campaign_description}
                readonly
              />
            </RenderIfComponent>
            {/* End campaign */}

            {/* Start promotion */}
            <RenderIfComponent
              isValid={[PRESS_RELEASE_TYPE.PROMOTION].includes(
                values.press_release_type
              )}
            >
              <div className="flex gap-x-[16px] w-full">
                <PrDivinput
                  label="브랜드명"
                  placeholder="브랜드명을 입력하세요"
                  rootClass="w-1/2"
                  required
                  value={values.brand_name}
                  readonly
                />
                <PrSingleRangePicker
                  label="공개 일자"
                  placeholder="프로모션 공개 날짜 또는 기간을 입력하세요 (ex. 2024.04.18~2024.05.18)"
                  rootClass="w-1/2"
                  required
                  readonly
                  format="yyyy년 M월 dd일"
                  startDate={
                    [PRESS_RELEASE_TYPE.PROMOTION].includes(
                      values.press_release_type
                    ) &&
                    values?.date &&
                    values?.date?.split("~")?.[0]
                      ? new Date(values?.date?.split("~")?.[0])
                      : null
                  }
                  endDate={
                    [PRESS_RELEASE_TYPE.PROMOTION].includes(
                      values.press_release_type
                    ) &&
                    values?.date &&
                    values?.date?.split("~")?.[1]
                      ? new Date(values?.date?.split("~")?.[1])
                      : null
                  }
                />
              </div>
              <PrTextarea
                label="프로모션 명"
                placeholder="내용을 입력하세요"
                rootClass="w-full"
                required
                value={values.promotion_name}
                readonly
              />
              <PrTextarea
                label="프로모션 설명 및 목적"
                placeholder="내용을 입력하세요"
                rootClass="w-full"
                required
                value={values.promotion_description}
                readonly
              />
            </RenderIfComponent>
            {/* End promotion */}

            {/* Start investment */}
            <RenderIfComponent
              isValid={[PRESS_RELEASE_TYPE.INVESTMENT].includes(
                values.press_release_type
              )}
            >
              <div className="flex gap-x-[16px] w-full">
                <PrDatePicker
                  label="발표일"
                  placeholder="투자 발표 날짜를 입력하세요 (ex. 2024.04.18)"
                  rootClass="w-1/2"
                  required
                  format="yyyy년 M월 dd일"
                  value={values.date}
                />
                <PrDivinput
                  label="투자자"
                  placeholder="내용을 입력하세요"
                  rootClass="w-1/2"
                  required
                  value={values.investor}
                  readonly
                />
              </div>
              <PrTextarea
                label="투자유치 내용 및 목적"
                placeholder="내용을 입력하세요"
                rootClass="w-full"
                required
                value={values.investment_content}
                readonly
              />
              <PrDivinput
                label="투자유치액"
                placeholder="내용을 입력하세요"
                rootClass="w-full"
                required
                value={values.investment_attraction}
                readonly
              />
            </RenderIfComponent>
            {/* End investment */}

            {/* Start contract */}
            <RenderIfComponent
              isValid={[PRESS_RELEASE_TYPE.CONTRACT].includes(
                values.press_release_type
              )}
            >
              <div className="flex gap-x-[16px] w-full">
                <PrDatePicker
                  label="계약일"
                  placeholder="계약 날짜를 입력하세요 (ex. 2024.04.18)"
                  rootClass="w-1/2"
                  required
                  value={values.date}
                  readonly
                  format="yyyy년 M월 dd일"
                />
              </div>
              <PrTextarea
                label="계약 내용 및 목적"
                placeholder="내용을 입력하세요"
                rootClass="w-full"
                required
                value={values.contract_content}
                readonly
              />
              <div className="flex gap-x-[16px] w-full">
                <PrDivinput
                  label="참석자"
                  placeholder="내용을 입력하세요"
                  rootClass="w-1/2"
                  value={values.participant}
                  required
                  readonly
                />
                <PrDivinput
                  label="계약장소"
                  placeholder="내용을 입력하세요"
                  rootClass="w-1/2"
                  value={values.place_of_contract}
                  required
                  readonly
                />
              </div>
            </RenderIfComponent>
            {/* End contract */}

            {/* Start achievement */}
            <RenderIfComponent
              isValid={[PRESS_RELEASE_TYPE.ARCHIEVEMENT].includes(
                values.press_release_type
              )}
            >
              <PrDatePicker
                label="일자"
                placeholder="날짜를 입력하세요 (ex. 2024.04.18)"
                rootClass="w-1/2"
                required
                value={values.date}
                readonly
                format="yyyy년 M월 dd일"
              />
              <PrTextarea
                label="주요 내용"
                placeholder="내용을 입력하세요"
                rootClass="w-full"
                required
                value={values.main_content}
                readonly
              />
              <PrDivinput
                label="기대효과"
                placeholder="내용을 입력하세요"
                rootClass="w-full"
                required
                value={values.benefit}
                readonly
              />
            </RenderIfComponent>
            {/* End achievement */}

            {/* Start social */}
            <RenderIfComponent
              isValid={[PRESS_RELEASE_TYPE.SOCIAL].includes(
                values.press_release_type
              )}
            >
              <div className="flex gap-x-[16px]">
                <PrDivinput
                  label="활동명"
                  placeholder="내용을 입력하세요"
                  rootClass="w-full"
                  required
                  value={values.activity_name}
                  readonly
                />
                <PrDatePicker
                  label="활동 일자"
                  placeholder="날짜를 입력하세요 (ex. 2024.04.18)"
                  rootClass="w-full"
                  required
                  value={values.date}
                  readonly
                  format="yyyy년 M월 dd일"
                />
              </div>
              <PrTextarea
                label="활동 설명"
                placeholder="내용을 입력하세요"
                rootClass="w-full"
                required
                value={values.activity_description}
                readonly
              />
              <PrDivinput
                label="기대효과"
                placeholder="내용을 입력하세요"
                rootClass="w-full"
                required
                value={values.benefit}
                readonly
              />
              <PrDivinput
                label="참여인사"
                placeholder="내용을 입력하세요"
                rootClass="w-1/2"
                value={values.participant}
                readonly
              />
            </RenderIfComponent>
            {/* End social */}

            {/* Start technology */}
            <RenderIfComponent
              isValid={[PRESS_RELEASE_TYPE.TECHNOLOGY].includes(
                values.press_release_type
              )}
            >
              <div className="flex gap-x-[16px]">
                <PrDivinput
                  label="연구 개발 분야"
                  placeholder="내용을 입력하세요"
                  rootClass="w-full"
                  required
                  value={values.rd_field}
                  readonly
                />
                <PrDatePicker
                  label="활동 일자"
                  placeholder="날짜를 입력하세요 (ex. 2024.04.18)"
                  rootClass="w-full"
                  required
                  value={values.date}
                  readonly
                  format="yyyy년 M월 dd일"
                />
              </div>
              <PrTextarea
                label="활동 설명"
                placeholder="내용을 입력하세요"
                rootClass="w-full"
                required
                value={values.activity_description}
                readonly
              />
              <PrDivinput
                label="기대효과"
                placeholder="내용을 입력하세요"
                rootClass="w-full"
                required
                value={values.benefit}
                readonly
              />
              <PrDivinput
                label="참여인사"
                placeholder="내용을 입력하세요"
                rootClass="w-1/2"
                value={values.participant}
                readonly
              />
            </RenderIfComponent>
            {/* End technology */}

            {/* Start character */}
            <RenderIfComponent
              isValid={[PRESS_RELEASE_TYPE.CHARACTER].includes(
                values.press_release_type
              )}
            >
              <div className="flex gap-x-[16px]">
                <PrDivinput
                  label="관련 이벤트"
                  placeholder="내용을 입력하세요"
                  rootClass="w-full"
                  required
                  value={values.related_event}
                  readonly
                />
                <PrDatePicker
                  label="이벤트 일자"
                  placeholder="날짜를 입력하세요 (ex. 2024.04.18)"
                  rootClass="w-full"
                  required
                  value={values.date}
                  readonly
                  format="yyyy년 M월 dd일"
                />
              </div>
              <PrTextarea
                label="이벤트 내용"
                placeholder="내용을 입력하세요"
                rootClass="w-full"
                required
                value={values.event_description}
                readonly
              />
              <PrDivinput
                label="인물의 역할"
                placeholder="내용을 입력하세요"
                rootClass="w-1/2"
                required
                value={values.character_role}
                readonly
              />
            </RenderIfComponent>
            {/* End character */}

            {/* Start policy */}
            <RenderIfComponent
              isValid={[PRESS_RELEASE_TYPE.POLICY].includes(
                values.press_release_type
              )}
            >
              <PrDatePicker
                label="변경 발표일"
                placeholder="날짜를 입력하세요 (ex. 2024.04.18)"
                rootClass="w-1/2"
                required
                value={values.date}
                readonly
                format="yyyy년 M월 dd일"
              />

              <PrTextarea
                label="내용"
                placeholder="내용을 입력하세요"
                rootClass="w-full"
                required
                value={values.policy_detail}
                readonly
              />
              <PrDivinput
                label="대상자 및 적용 범위"
                placeholder="내용을 입력하세요"
                rootClass="w-full"
                required
                value={values.target}
                readonly
              />
            </RenderIfComponent>
            {/* End policy */}

            {/* Start orther */}
            <RenderIfComponent
              isValid={[PRESS_RELEASE_TYPE.OTHER].includes(
                values.press_release_type
              )}
            >
              <PrDatePicker
                label="일자"
                placeholder="날짜를 입력하세요 (ex. 2024.04.18)"
                rootClass="w-1/2"
                required
                format="yyyy년 M월 dd일"
                value={values.date}
                readonly
              />

              <PrTextarea
                label="주요 내용"
                placeholder="내용을 입력하세요"
                rootClass="w-full"
                required
                value={values.main_content}
                readonly
              />
            </RenderIfComponent>
            {/* End orther */}

            <div className="flex gap-x-[16px] w-full">
              <RenderIfComponent
                isValid={[PRESS_RELEASE_TYPE.PRODUCT].includes(
                  values.press_release_type
                )}
              >
                <PrDivinput
                  label="판매처"
                  placeholder="내용을 입력하세요"
                  rootClass="w-1/2"
                  value={values.outlets}
                  readonly
                />
              </RenderIfComponent>
              <PrDivinput
                label="관계자 이름"
                placeholder="이름 / 소속 / 직책"
                rootClass="w-1/2"
                value={values.official_name}
                readonly
              />
            </div>
            <PrDivinput
              label="관계자 멘트"
              placeholder="내용을 입력하세요"
              rootClass="w-full"
              value={values.official_comment}
              readonly
            />
          </RenderIfComponent>
          <RenderIfComponent isValid={values?.is_manual}>
            <PrTextarea
              label="내용"
              placeholder="내용을 입력하세요"
              rootClass="w-full"
              required
              value={values.detail}
              minHeight={320}
              readonly
            />
          </RenderIfComponent>
          <PrInputTags
            label="키워드"
            placeholder="키워드를 입력하고 Enter (최대 10개까지 입력 가능)"
            maxLength={50}
            maxTags={10}
            value={values.keywords}
            readonly
            isHiddenAction={true}
          />

          <PrUpload
            label="이미지 · 영상 첨부"
            maxFile={10}
            required
            defaultFiles={values.attachments}
            readonly
            isHiddenUploading={true}
          />
          <RenderIfComponent isValid={!values?.is_manual}>
            <PrTextarea
              label="회사소개"
              subLabel="사업 분야, 설립일, 주요 제품 및 서비스, 비전, 본사 위치, 설립자, 직원 수 등을 요약"
              placeholder="내용을 입력하세요"
              rootClass="w-full"
              tooltipContent={renderTooltipCompany()}
              required
              value={values.about_us}
              readonly
            />
            <PrDivinput
              label="이름 / 이메일 / 웹사이트"
              placeholder="prhub@prhub.com"
              rootClass="w-full"
              value={renderValue(values)}
              readonly
            />
            <PrTextarea
              label="메모"
              placeholder="매체에 전달할 추가 내용이나 요청사항을 작성하세요"
              rootClass="w-full"
              value={values.note}
              readonly
            />
          </RenderIfComponent>
        </div>
      </form>
      </div>
    </Modal>
  );
};
export default ModalDetail;
