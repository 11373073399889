import { Modal } from "antd";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useState, useEffect, useRef } from "react";
import imageCompression from "browser-image-compression";
//
import { ReactComponent as CloseSvg } from "asset/svg/closeModal.svg";
import { ReactComponent as PlusSvg } from "asset/svg/plus.svg";
import { ReactComponent as InfoSvg } from "asset/svg/info.svg";
import { ReactComponent as ArrowRightSvg } from "asset/svg/arrowRight.svg";
import { ReactComponent as Close2Svg } from "asset/svg/close-2.svg";
import { ReactComponent as CheckboxSvg } from "asset/svg/checkbox.svg";
import { ReactComponent as CheckboxActiveSvg } from "asset/svg/checkboxActive.svg";
import { ReactComponent as PlayVideoSvg } from "asset/svg/playVideo.svg";
import PrTab from "./PrTab";
import RenderIfComponent from "./RenderIfComponent";
import PrButton from "./PrButton";
import PrTextarea from "./PrTextarea";
import { ATTACHMENT_TYPE } from "contants/app.contant";
import helper from "helper";
import PrInput from "./PrInput";
import { useToast } from "context/toast.context";
import PrLoading from "./PrLoading";

const PrModalUpload = ({
  defaultFiles,
  isModalOpen,
  handleUploadFile,
  handleCancel,
  maxFile = 2,
}) => {
  const { openToast } = useToast();
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const objectURLs = [];

  const handleDeleteFile = (index) => {
    const updatedFiles = structuredClone(files).filter((_, i) => i !== index);
    if (
      !updatedFiles.some((file) => file.is_defaut) &&
      updatedFiles.length > 0
    ) {
      updatedFiles[0].is_defaut = 1;
    }
    setFiles(updatedFiles);
  };

  const handleSetDefault = (index) => {
    const updatedFiles = files.map((file, i) => ({
      ...file,
      is_defaut: i === index ? 1 : 0,
    }));
    setFiles(updatedFiles);
  };

  const handleModalClose = () => {
    if (loading) {
      setLoading(false);
      setFiles([]); // Clear the files list
    }
    handleCancel();
  };

  const validationSchema = Yup.object().shape({
    iframe: Yup.string()
      .matches(
        /<iframe[^>]+src=["']([^"']+)["'][^>]*><\/iframe>/i,
        "Invalid iframe URL"
      )
      .required("Iframe URL is required"),
  });

  const formik = useFormik({
    initialValues: {
      iframe: "",
    },
    validationSchema,
    onSubmit: (values) => {
      const iframeObject = [
        {
          attachment_type: ATTACHMENT_TYPE.EMBED,
          filename: helper.getTitleFromIframe(values.iframe || ""),
          description: "",
          path: values.iframe,
          is_defaut: 0,
        },
      ];
      setFiles([...files, ...iframeObject]);
      formik.resetForm();
    },
  });

  const handleUpload = async (fileList) => {
    if (files.filter((file) => file.file)?.length + fileList?.length > maxFile) {
      openToast(`최대 ${maxFile}개의 파일을 업로드할 수 있습니다`, "error");
      return;
    }
  
    const options = {
      maxSizeMB: 1,
      useWebWorker: true,
    };
  
    setLoading(true);
  
    try {
      const updatedFiles = [];
      const promises = Array.from(fileList).map(async (file) => {
        try {
          const isLargeFile = file.size / 1024 / 1024 > 10;
          const isLargeFilePreview = file.size / 1024 / 1024 > 1;
          let compressedFile = file;
          let compressedFilePreview = file;
  
          if (isLargeFile) {
            compressedFile = await imageCompression(file, options);
          }
  
          if (isLargeFilePreview) {
            compressedFilePreview = await imageCompression(compressedFile, {
              maxSizeMB: 0.1,
              useWebWorker: true,
              maxWidthOrHeight: 300,
            });
          }
  
          const pathPreview = URL.createObjectURL(compressedFilePreview);
          objectURLs.push(pathPreview);
  
          const newFile = {
            attachment_type: ATTACHMENT_TYPE.IMAGE,
            filename: file.name,
            description: "",
            path: pathPreview,
            is_defaut: 0,
            file: compressedFile,
            status: "uploading",
          };
  
          updatedFiles.push(newFile);
        } catch (error) {
          console.error("Error processing file:", file, error);
        }
      });
  
      await Promise.all(promises);
  
      setFiles([...(files || []), ...updatedFiles]);
    } catch (error) {
      console.error("Error in handleUpload:", error);
      openToast("이미지 오류", "error");
    } finally {
      setLoading(false);
    }
  };

  const tabItemUpload = () => {
    return (
      <div class={`flex items-center justify-center w-full mt-[16px]`}>
        <label
          for="dropzone-file"
          class={`flex flex-col items-center justify-center w-full max-h-[160px] p-[16px] min-h-[160px] border-[1.5px] border-[#E1E1EB] border-dashed rounded-lg  bg-[#F9F9FB] dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600 ${!loading ? `cursor-pointer` : `cursor-no-drop`}`}
        >
          <div class="flex flex-col items-center justify-center pt-5 pb-6">
            <p className="ant-upload-drag-icon">
              <PlusSvg className="mx-auto" />
            </p>
            <p className="text-[16px] font-[600] text-[#555560] mt-[16px]">
              여기를 클릭하거나, 파일을 끌어다 놓으세요.
            </p>
            <p className="text-[13px] text-[#888896]">파일(jpg, png)</p>
          </div>
          <input
            id="dropzone-file"
            type="file"
            className="hidden"
            accept=".jpg,.png,.jpeg"
            multiple
            disabled={loading}
            onChange={(e) => {
              handleUpload(e.target.files);
              e.target.value = ''
            }}
          />
        </label>
      </div>
    );
  };

  const tabItemVideo = () => (
    <form onSubmit={formik.handleSubmit} className="flex flex-col gap-y-[16px]">
      <div className="text-[13px] font-[400] text-[#888896] flex items-center bg-[#F9F9FB] py-[8px] px-[12px] rounded-[8px] mt-[16px]">
        <InfoSvg className="mr-[8px]" />
        유튜브에 업로드 된 동영상에서 마우스 우클릭 <ArrowRightSvg />
        ‘소스코드’ 복사 후 붙여넣기
      </div>
      <PrTextarea
        placeholder="동영상 태그를 입력해 주세요"
        value={formik.values.iframe}
        onChange={(e) => formik.setFieldValue("iframe", e.target.value)}
        name="iframe"
      />
      <div className="flex justify-end">
        <PrButton
          htmlType="submit"
          type="primary"
          height="40px"
          width="122px"
          disabled={!formik.isValid || !formik.dirty}
        >
          <span className="text-[14px] !font-[500]">영상 첨부</span>
        </PrButton>
      </div>
    </form>
  );

  const handleDescriptionChange = (e, index) => {
    const { value } = e.target;

    const updatedFiles = [...files];

    updatedFiles[index].description = value;

    setFiles(updatedFiles);
  };

  const optionTab = [
    {
      title: "이미지 첨부",
      content: tabItemUpload(),
    },
    {
      title: "동영상 첨부",
      content: tabItemVideo(),
    },
  ];

  const handleSubmit = () => {
    handleUploadFile(files);
  };

  useEffect(() => {
    if (files?.length && !files?.some((file) => file.is_defaut)) {
      const updatedFiles = [...files];
      updatedFiles[0].is_defaut = 1;
      // setFiles(updatedFiles);
    }
  }, [files]);

  useEffect(() => {
    if (isModalOpen) {
      setFiles(defaultFiles);
    }
    return () => {
      objectURLs.forEach((url) => URL.revokeObjectURL(url));
    };
  }, [defaultFiles, isModalOpen]);

  return (
    <Modal
      open={isModalOpen}
      onCancel={handleModalClose}
      footer={null}
      centered
      closable={false}
      className="pr-modal-upload"
      width={560}
      key={isModalOpen ? 'modal-open' : 'modal-closed'}
    >
      <div className="flex justify-between items-center">
        <div className="text-[24px] font-[700] text-[#2C2C33]">
          파일 첨부하기
        </div>
        <CloseSvg className="cursor-pointer" onClick={handleModalClose} />
      </div>
      <PrTab
        tabs={optionTab}
        defaultActiveTab={activeTab}
        disable={loading}
        rootClass="mt-[16px]"
        onCallBack={(e) => {
          !loading && setActiveTab(e);
        }}
      />
      <div className="relative">
        <RenderIfComponent isValid={loading}>
          <div
            className="absolute top-[50%] left-[50%] z-[1000] w-full h-full flex items-center justify-center"
            style={{
              transform: "translate(-50%, -50%)",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
            }}
          >
            <PrLoading />
          </div>
        </RenderIfComponent>
        <div className="h-full mt-[20px]">
          <div className="text-[#555560] text-[14px] font-[600]">첨부 파일</div>
          <table className="w-full text-left h-full mt-[10px] block">
            <thead className="inline-table w-full">
              <tr className="text-[13px] text-[#888896] h-[40px] bg-[#F9F9FB] rounded-[6px]">
                <th className="w-[48px] pl-[16px] pr-[10px] whitespace-nowrap py-[9px] !font-[400]">
                  대표
                </th>
                <th
                  className="w-[80px] !font-[400] text-center"
                  style={{ padding: "9px 0px 9px 8px" }}
                >
                  썸네일
                </th>
                <th
                  className="!font-[400]"
                  style={{ padding: "9px 0px 9px 8px" }}
                >
                  파일 설명
                </th>
                <th className="w-[40px] pr-[16px] !font-[400]">삭제</th>
              </tr>
            </thead>
            <RenderIfComponent isValid={files?.length}>
              <tbody className="max-h-[226px] overflow-y-auto overflow-x-hidden block">
                {files?.map((item, index) => (
                  <tr key={index}>
                    <td
                      className="min-w-[48px] pl-[16px] py-[12px] cursor-pointer"
                      onClick={() => handleSetDefault(index)}
                    >
                      {!item.is_defaut ? (
                        <CheckboxSvg className="mx-auto" />
                      ) : (
                        <CheckboxActiveSvg className="mx-auto" />
                      )}
                    </td>
                    <td className="min-w-[80px] !py-[12px]">
                      <div className="flex items-center justify-center">
                        <div className="relative max-w-[56px] max-h-[42px] rounded-[8px]">
                          <img
                            src={
                              item.attachment_type === ATTACHMENT_TYPE.EMBED
                                ? helper.getThumbnailUrlFromIframe(item.path)
                                : !item.file
                                ? item.thumbnail_url || item.url
                                : item.path
                            }
                            alt=""
                            className="min-w-[56px] min-h-[42px] max-w-[56px] max-h-[42px] border border-[rgba(0, 0, 0, 0.08)] rounded-[8px] object-cover"
                          />
                          <RenderIfComponent isValid={item.is_defaut}>
                            <div className="text-[10px] font-[600] text-[#FFF] px-[4px] py-[10px] h-[16px] flex items-center justify-center absolute top-[2px] left-[2px] bg-[#EC2929] rounded-[6px]">
                              대표
                            </div>
                          </RenderIfComponent>
                          <RenderIfComponent
                            isValid={
                              item.attachment_type === ATTACHMENT_TYPE.EMBED ||
                              item.attachment_type === ATTACHMENT_TYPE.VIDEO
                            }
                          >
                            <div className="absolute bottom-[2px] right-[2px]">
                              <PlayVideoSvg />
                            </div>
                          </RenderIfComponent>
                        </div>
                      </div>
                    </td>
                    <td className="py-[12px] !font-[400] w-full !px-[8px]">
                      <RenderIfComponent
                        isValid={item.attachment_type !== ATTACHMENT_TYPE.EMBED}
                      >
                        <div className="flex flex-col gap-y-[4px]">
                          <div className="text-[14px] font-[600] line-clamp-1 text-[#555560]">
                            {item.filename}
                          </div>
                          <PrInput
                            value={item.description}
                            placeholder="이미지 설명을 입력해 주세요"
                            rootClass="!gap-y-[0px]"
                            className={
                              "!px-[8px] !py-[6px] rounded-[8px] text-[12px]"
                            }
                            height="30px"
                            onChange={(e) => handleDescriptionChange(e, index)}
                          />
                        </div>
                      </RenderIfComponent>
                      <RenderIfComponent
                        isValid={item.attachment_type === ATTACHMENT_TYPE.EMBED}
                      >
                        <div className="flex items-center gap-[8px]">
                          <div className="line-clamp-2 font-[600] text-[#555560]">
                            {item.filename}
                          </div>
                        </div>
                      </RenderIfComponent>
                    </td>
                    <td className="py-[12px] px-[10px] cursor-pointer">
                      <div
                        className="cursor-pointer min-w-[32px] min-h-[32px] bg-[#FEEFEF] rounded-[8px] flex justify-center items-center"
                        onClick={() => handleDeleteFile(index)}
                      >
                        <Close2Svg />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </RenderIfComponent>
            <RenderIfComponent isValid={!files?.length}>
              <div className="text-[13px] flex justify-center items-center text-[#9397A1] min-h-[160px]">
                첨부한 이미지 또는 영상이 없습니다.
              </div>
            </RenderIfComponent>
          </table>
        </div>
      </div>
      <div className="flex justify-center mt-[24px]">
        <PrButton
          type="primary"
          label="첨부완료"
          width="160px"
          height="56px"
          disabled={!files?.length || loading}
          onClick={handleSubmit}
          htmlType={"button"}
        />
      </div>
    </Modal>
  );
};

export default PrModalUpload;
