import { createContext } from "react";
import { ToastContextProvider } from "./toast.context";
import { ConfirmContextProvider } from "./confirm.context";

const Context = createContext(null);

export const ContextProvider = ({ children }) => {
  return (
    <Context.Provider value="">
      <ToastContextProvider>
        <ConfirmContextProvider>{children}</ConfirmContextProvider>
      </ToastContextProvider>
    </Context.Provider>
  );
};

export default Context;
