import React, { useState } from "react";
import { ConfigProvider, Dropdown, Flex } from "antd";
import { useNavigate } from "react-router-dom";
import authApi from "services/auth";
import { useSelector } from "react-redux";
import helper from "helper";

function ProfileDropdown(props) {
  const navigate = useNavigate();
  const [openProfile, setOpenProfile] = useState(false);
  const profile = useSelector((state) => state?.Auth?.data);

  const handleMenuClick = async (e) => {
    switch (e.key) {
      case "profile":
        navigate("/profile");
        break;
      case "logout":
        try {
          await authApi.logout();
        } catch (error) {
          console.log("error121212", error);
        } finally {
          localStorage.removeItem("auth");
          navigate("/login");
        }
        break;

      default:
        break;
    }
  };

  const AdvertiserItems = [
    {
      label: <p className='text-base font-normal text-[#555560]'>정보 수정</p>,
      key: "profile",
      icon: (
        <svg
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M9.23077 9.23077C10.9514 9.23077 12.3462 7.83596 12.3462 6.11538C12.3462 4.39481 10.9514 3 9.23077 3C7.51019 3 6.11539 4.39481 6.11539 6.11538C6.11539 7.83596 7.51019 9.23077 9.23077 9.23077Z'
            stroke='#888896'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M7.15385 19.6157H5C3.89543 19.6157 3 18.7203 3 17.6157V17.5388C3.01102 16.4834 3.28935 15.4481 3.80899 14.5294C4.32863 13.6108 5.07263 12.8389 5.97149 12.2857C6.87035 11.7326 7.89474 11.4163 8.94898 11.3664C10.0032 11.3165 11.0529 11.5346 12 12.0003'
            stroke='#888896'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M21.0002 14.075L14.4787 20.5966L11.5294 20.9981L11.9448 18.0489L18.4525 11.5273L21.0002 14.075Z'
            stroke='#888896'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      ),
    },
    {
      label: "로그아웃",
      key: "logout",
      icon: (
        <svg
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M13 4H7C5.89543 4 5 4.89543 5 6V18C5 19.1046 5.89543 20 7 20H13'
            stroke='#888896'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M11 12H19M19 12L16.6 9M19 12L16.6 15'
            stroke='#888896'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      ),
    },
  ];

  const MediaItems = [
    {
      label: "로그아웃",
      key: "logout",
      icon: (
        <svg
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M13 4H7C5.89543 4 5 4.89543 5 6V18C5 19.1046 5.89543 20 7 20H13'
            stroke='#888896'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M11 12H19M19 12L16.6 9M19 12L16.6 15'
            stroke='#888896'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      ),
    },
  ];
  const menuProps = {
    items: helper.isUserAdvertiser() ? AdvertiserItems : MediaItems,
    onClick: handleMenuClick,
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Dropdown: {
            paddingXXS: 8,
            controlPaddingHorizontal: 12,
            paddingBlock: 12,
          },
        },
      }}
    >
      <Dropdown
        menu={menuProps}
        placement='bottomRight'
        className='group py-2'
        trigger={"click"}
        onOpenChange={(open) => setOpenProfile(open)}
      >
        <Flex className='cursor-pointer text-base font-medium text-[#555560]' gap={8}>
          {profile?.name}
          <svg
            className={`transition-all transform duration-700 ${
              openProfile ? "rotate-0" : "rotate-180"
            }`}
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M8 14L12 10L16 14'
              stroke='#555560'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </Flex>
      </Dropdown>
    </ConfigProvider>
  );
}

export default ProfileDropdown;
