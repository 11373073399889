import { THEME, USER_TYPE } from "contants/app.contant";
import imgDefault from "asset/images/image-default.png";
const handleImageError = (event) => {
  if (event && event.target) {
    event.target.src = imgDefault;

    event.target.classList.add("image-error");

    event.target.onerror = null;
  }
};

const getThumbnailUrlFromIframe = (iframe) => {
  const regExp = /src="([^"]+)"/;
  const match = regExp.exec(iframe);

  if (!match || !match[1]) {
    return "";
  }

  try {
    const srcIframe = match[1];

    const url = new URL(srcIframe);
    const [, , videoId] = url.pathname.split("/");
    const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/0.jpg`;

    return thumbnailUrl;
  } catch {
    return "";
  }
};

const getTitleFromIframe = (iframe) => {
  const regExp = /title="([^"]+)"/;
  const match = regExp.exec(iframe);

  if (!match || !match[1]) {
    return "";
  }

  return match[1];
};

const heightContent = () => {
  return `calc(100vh - ${THEME.HEADER_HEIGHT + THEME.FOOTER_HEIGHT}px)`;
};

export const removeEmptyFields = (obj) => {
  const result = {};

  for (const key in obj) {
    if (obj[key] !== null && obj[key] !== undefined && obj[key] !== "") {
      if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
        const nestedObject = removeEmptyFields(obj[key]);
        if (Object.keys(nestedObject).length > 0) {
          result[key] = nestedObject;
        }
      } else if (Array.isArray(obj[key])) {
        const filteredArray = obj[key]
          .map((item) =>
            typeof item === "object" ? removeEmptyFields(item) : item
          )
          .filter((item) => item !== null && item !== undefined);

        if (filteredArray.length > 0) {
          result[key] = filteredArray;
        }
      } else {
        result[key] = obj[key];
      }
    }
  }

  return result;
};

export const removeEmptyStringFields = (obj) => {
  const result = {};

  for (const key in obj) {
    if (typeof obj[key] === "string" && (obj[key] === "" || obj[key] === null)) {
      continue; 
    }

    if (typeof obj[key] === "object" && !Array.isArray(obj[key]) && obj[key] !== null) {
      const nestedObject = removeEmptyStringFields(obj[key]);
      result[key] = nestedObject; 
    } else if (Array.isArray(obj[key])) {
      const filteredArray = obj[key]
        .map((item) =>
          typeof item === "object" && item !== null ? removeEmptyStringFields(item) : item
        )
        .filter((item) => item !== null)

      result[key] = filteredArray;
    } else {
      result[key] = obj[key]; 
    }
  }

  return result;
};

function validateEmail(email) {
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return regex.test(email);
}

function getCurrentUser() {
  return JSON.parse(localStorage.getItem("auth") || null);
}

function isUserAdvertiser() {
  const currentUser = JSON.parse(localStorage.getItem("auth") || null);
  return USER_TYPE.ADVERTISER === currentUser?.user?.type;
}

function getUserType() {
  const currentUser = JSON.parse(localStorage.getItem("auth") || null);
  return currentUser?.user?.type;
}
const formatNumber = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

function numberWithCommas(x) {
  if(!x) return
  return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

const resizeImageToSize = (file, targetSizeMB = 10) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      canvas.width = img.width;
      canvas.height = img.height;

      ctx.drawImage(img, 0, 0);

      let quality = 1.0; 
      const maxBytes = targetSizeMB * 1024 * 1024; 

      const compressImage = () => {
        canvas.toBlob((blob) => {
          if (blob.size <= maxBytes || quality <= 0.1) {
            resolve(blob);
          } else {
            quality -= 0.1;
            compressImage();
          }
        }, file.type || 'image/jpeg', quality);
      };

      compressImage(); 
    };

    img.onerror = (err) => reject(err);
  });
};

const helper = {
  handleImageError,
  getThumbnailUrlFromIframe,
  getTitleFromIframe,
  heightContent,
  validateEmail,
  getCurrentUser,
  isUserAdvertiser,
  getUserType,
  numberWithCommas,
  formatNumber,
  resizeImageToSize
};
export default helper;
