const config = {
  api: {
    API_URL: `${process.env.REACT_APP_API_URL}`,
  },
  page:{
    LANDING_PAGE: `${process.env.REACT_APP_LANDING_PAGE}`,
  }
};

export default config;
