import axiosInstance from "../../core/axiosInstance";

const path = "/v1/systems";

const systemApi = {
  getConfigs() {
    return axiosInstance.get(`${path}/configs`);
  },
  uploadFiles(files) {
    const formData = new FormData();
    files.forEach(file => {
      formData.append("files", file);
    });
    return axiosInstance.post(`/v1/upload/files`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
  },
};

export default systemApi;
