import { Tooltip } from "antd";
//
import RenderIfComponent from "components/RenderIfComponent";
import { ReactComponent as RequireSvg } from "asset/svg/require.svg";
import { ReactComponent as HelpSvg } from "asset/svg/help.svg";

const PrDivinput = ({
  label,
  subLabel,
  rootClass = "",
  required = false,
  tooltipContent,
  classLabel,
  placeholder = "Enter text",
  value,
}) => {
  const renderTooltip = (content) => <div className="p-[16px]">{content}</div>;

  return (
    <div className={`pr-input flex flex-col gap-y-[8px] ${rootClass}`}>
      <div>
        <RenderIfComponent isValid={!!label}>
          <div className="flex items-center">
            <label
              className={`text-[18px] font-[600] text-[#2C2C33] ${
                classLabel ? classLabel : ""
              }`}
            >
              {label}
            </label>
            {required && <RequireSvg />}
            <RenderIfComponent isValid={!!tooltipContent}>
              <Tooltip
                rootClassName="p-[0px]"
                placement="bottom"
                title={renderTooltip(tooltipContent)}
              >
                <HelpSvg className="ml-[8px] cursor-pointer" />
              </Tooltip>
            </RenderIfComponent>
          </div>
        </RenderIfComponent>
        {!!subLabel?.length && (
          <span className="text-[14px] text-[#888896] font-[400]">
            {subLabel}
          </span>
        )}
      </div>
      <div className="p-[16px] border border-[#E1E1EB] rounded-[12px] rounded-[12px] h-[56px]">
        <div className={`text-[16px] pb-[4px] font-[400] mb-[-10px] flex items-center overflow-x-auto overflow-y-hidden whitespace-nowrap ${!value?.length ? 'text-[#B4B4BD]' : 'text-[#2C2C33]'}`}>
            {value ? value : placeholder}
        </div>
      </div>
    </div>
  );
};
export default PrDivinput;
