import React from "react";
import { Button, Tooltip } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import RenderIfComponent from "./RenderIfComponent";

const PrButton = ({
  htmlType = "button" | "submit",
  label = "Click me",
  children,
  type = "default", // "primary", "dashed", "text", "link"
  size = "middle", // "small", "middle", "large"
  shape = "default", // "default", "round", "circle"
  icon = null,
  loading = false,
  disabled = false,
  danger = false,
  tooltipContent = "",
  rootClass = "",
  onClick,
  defaultColor = "#2C2C33",
  width = "auto",
  height = "60px",
  labelClass = "",
}) => {
  const renderTooltip = (content, children) => {
    if (content) {
      return <Tooltip title={content}>{children}</Tooltip>;
    }
    return children;
  };

  const buttonStyle =
    defaultColor && type === "primary"
      ? { backgroundColor: defaultColor, borderColor: defaultColor }
      : {};

  return renderTooltip(
    tooltipContent,
    <Button
      htmlType={htmlType}
      type={type}
      size={size}
      shape={shape}
      icon={loading ? <LoadingOutlined /> : icon}
      loading={loading}
      disabled={disabled}
      danger={danger}
      className={`${rootClass} pr-button px-[24px] py-[14px] h-auto rounded-[12px]`}
      onClick={onClick}
      style={{ ...buttonStyle, ...{ width, height } }}
    >
      <RenderIfComponent isValid={!!children}>{children}</RenderIfComponent>
      <RenderIfComponent isValid={!children}>
        <span className={`text-[18px] font-[500] ${labelClass}`}>
          {" "}
          {label}{" "}
        </span>
      </RenderIfComponent>
    </Button>
  );
};

export default PrButton;
