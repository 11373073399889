import { BrowserRouter } from "react-router-dom";
import { ConfigProvider } from "antd";
import "dayjs/locale/ko";
import locale from "antd/locale/ko_KR";
//
import "./App.css";
import { ContextProvider } from "./context";
import AppRouter from "./router/Router";

function App() {
  return (
    <BrowserRouter>
      <ConfigProvider
        locale={locale}
        theme={{
          fontFamily: "Pretendard",
          components: {
            Button: {
              colorPrimary: "rgb(0,0,0)",
              paddingInline: 24,
              paddingBlock: 12,
              borderRadius: 10,
              colorPrimaryHover: "rgb(0,0,0)",
              controlHeight: 46,
              colorPrimaryActive: "rgb(0,0,0)",
              fontFamily: "Pretendard",
            },
            Table: {
              headerBg: "#EFEFF5",
              cellFontSize: 14,
              colorText:'#555560',
              fontFamily: "Pretendard",
            },
            Pagination: {
              colorPrimary: "rgb(0,0,0)",
              colorPrimaryHover: "rgb(0,0,0)",
              fontFamily: "Pretendard",
            },
            Modal:{
              borderRadiusLG: 24,
              fontFamily: "Pretendard",
            },
            Input:{
              fontFamily: "Pretendard",
            },
            Select:{
              fontFamily: "Pretendard",
            },
            Radio:{
              fontFamily: "Pretendard",
            },
            Checkbox:{
              fontFamily: "Pretendard",
            },
            Tooltip:{
              fontFamily: "Pretendard",
            }
          },
        }}
      >
        <ContextProvider>
          <AppRouter />
        </ContextProvider>
      </ConfigProvider>
    </BrowserRouter>
  );
}

export default App;
