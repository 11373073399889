import React, { useEffect, useState } from "react";

const Tab = ({ index, title, activeTab, onClick, isBorder,disable }) => {
  return (
    <div
      className={`text-[16px] font-[500] ${!disable ? `cursor-pointer` : `cursor-no-drop`} ${
        index === activeTab
          ? "text-[#2C2C33] border-b-[2px] border-[#2C2C33]"
          : `text-[#888896] ${
              isBorder ? "border-b-[2px] border-[#E1E1EB]" : ""
            }`
      }`}
      onClick={() => onClick(index)}
      style={{ padding: "4px 24px 12px 24px" }}
    >
      {title}
    </div>
  );
};

const PrTab = ({
  tabs,
  defaultActiveTab = 0,
  rootClass,
  onChange,
  isBorder = true,
  onlyBorderActive = false,
  onCallBack,
  disable = false,
}) => {
  const [activeTab, setActiveTab] = useState(defaultActiveTab);

  const handleTabClick = (tabId) => {
    if (onCallBack) return onCallBack(tabId);
    setActiveTab(tabId);
    onChange && onChange(tabId);
  };
  useEffect(() => {
    setActiveTab(defaultActiveTab);
  }, [defaultActiveTab]);

  return (
    <div className={`pr-tabs ${rootClass ? rootClass : ""}`}>
      <div className="flex">
        {tabs?.map((tab, index) => (
          <Tab
            key={index}
            index={index}
            title={tab.title}
            disable={disable}
            activeTab={activeTab}
            isBorder={
              onlyBorderActive ? (index == activeTab ? true : false) : isBorder
            }
            onClick={() => handleTabClick(index)}
          />
        ))}
      </div>
      <div className="tab-content">{tabs?.[activeTab]?.content}</div>
    </div>
  );
};

export default PrTab;
