import React, { useEffect, useState } from "react";
import { Select, Tooltip, Checkbox } from "antd";
//
import RenderIfComponent from "components/RenderIfComponent";
import { ReactComponent as ArrowDownSvg } from "asset/svg/arrowDown.svg";
import { ReactComponent as RequireSvg } from "asset/svg/require.svg";
import { ReactComponent as HelpSvg } from "asset/svg/help.svg";

const PrSelectCheckbox = ({
  options,
  placeholder = "Select an option",
  customIcon = <ArrowDownSvg />,
  onChange,
  allowClear = false,
  label,
  rootClass = "",
  required = false,
  disabled = false,
  showSearch = false,
  tooltipContent,
  value,
  keyValue = "value",
  keyLabel = "label",
  subLabel,
  error,
  readonly,
  isHiddenAction,
  classLabel
}) => {
  const [selectedValues, setSelectedValues] = useState(value);

  const handleSelectChange = (newValues) => {
    setSelectedValues(newValues);
    if (onChange) {
      onChange(newValues);
    }
  };

  const renderTooltip = (children) => (
    <div className="p-[16px]">{children}</div>
  );

  const labelRender = (props) => {
    const { value } = props;
    const item = options?.find(item => item?.[keyValue] === value);
    return <>{item?.[keyLabel]}</>;
  };

  useEffect(() => {
    setSelectedValues(value)
  },[value]);

  return (
    <div className={`pr-select-multiple flex flex-col gap-y-[8px] ${rootClass}`}>
      <div>
        <RenderIfComponent isValid={!!label}>
          <div className="flex items-center">
            <label className={`text-[18px] font-[600] text-[#2C2C33] ${classLabel ? classLabel : ''}`}>
              {label}
            </label>
            {required && <RequireSvg />}
            <RenderIfComponent isValid={!!tooltipContent}>
              <Tooltip
                rootClassName="p-[0px]"
                placement="bottom"
                title={renderTooltip(tooltipContent)}
              >
                <HelpSvg className="ml-[8px] cursor-pointer" />
              </Tooltip>
            </RenderIfComponent>
          </div>
        </RenderIfComponent>
        {!!subLabel?.length && (
          <span className="text-[14px] text-[#888896] font-[400]">
            {subLabel}
          </span>
        )}
      </div>
      <RenderIfComponent isValid={!isHiddenAction}>
        <Select
          mode="multiple"
          value={selectedValues}
          placeholder={placeholder}
          suffixIcon={customIcon}
          onChange={handleSelectChange}
          allowClear={allowClear}
          className="w-full"
          disabled={disabled}
          labelRender={labelRender}
          showSearch={showSearch}
          status={error ? "error" : undefined}
          style={{
            pointerEvents: readonly ? "none" : "auto",
          }}
          maxTagCount={'responsive'}
        >
          {options?.map(option => (
            <Select.Option key={option[keyValue]} value={option[keyValue]}>
              <div className="flex items-center gap-x-[8px]">
                <Checkbox
                  checked={selectedValues?.includes(option[keyValue])}
                  onChange={() => {
                    const newSelectedValues = selectedValues?.includes(option[keyValue])
                      ? selectedValues?.filter(val => val !== option[keyValue])
                      : [...selectedValues, option[keyValue]];
                    handleSelectChange(newSelectedValues);
                  }}
                />
                <span className="font-[400] text-[16px] text-[#555560]">{option[keyLabel]}</span>
              </div>
            </Select.Option>
          ))}
        </Select>
      </RenderIfComponent>
      <RenderIfComponent isValid={isHiddenAction}>
        <div className="flex flex-wrap gap-[8px]">
          {Array.from(options).map((option) => (
            <div
              key={option[keyValue]}
              className="bg-[#EFEFF5] rounded-[8px] px-[8px] py-[4px] flex items-center justify-center gap-x-[4px] text-[13px] text-[#555560] h-[29px]"
            >
              {option[keyLabel]}
            </div>
          ))}
        </div>
      </RenderIfComponent>
    </div>
  );
};

export default PrSelectCheckbox;
