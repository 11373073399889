import React, { Suspense } from "react";
import { Navigate } from "react-router-dom";
import { useEffect } from "react";
// import { useDispatch } from "react-redux";
//
import Layout from "layouts/index";
import { useDispatch } from "react-redux";
import { getProfile } from "store/thunks";
// import { getCategory } from "../store/thunks";
// import PrLoadingSpinner from "components/PrLoadingSpinner";

export const ProtectedRoute = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(getCategory());
    dispatch(getProfile());
  }, [dispatch]);

  const auth = JSON.parse(localStorage.getItem("auth") || null);
  const user = auth?.meta?.access_token?.token;
  if (!user) {
    // user is not authenticated
    return <Navigate to="/login" />;
  }

  return (
    <React.Fragment>
      <Suspense
      //  fallback={<PrLoadingSpinner />}
       >
        <Layout>{children}</Layout>
      </Suspense>
    </React.Fragment>
  );
};
