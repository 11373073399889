import React from "react";
import { Input, Tooltip } from "antd";
import RenderIfComponent from "components/RenderIfComponent";
import { ReactComponent as RequireSvg } from "asset/svg/require.svg";
import { ReactComponent as HelpSvg } from "asset/svg/help.svg";
import { ReactComponent as ErrorSvg } from "asset/svg/error.svg";

const PrInput = ({
  defaultValue,
  placeholder = "Enter text",
  onChange,
  allowClear = false,
  label,
  subLabel,
  rootClass = "",
  required = false,
  tooltipContent,
  value,
  maxLength = 500,
  disabled = false,
  prefix, // optional icon or text to display at the start of the input
  suffix, // optional icon or text to display at the end of the input
  type = "text", // input type: text, number, email, etc.
  error,
  className,
  height = "56px",
  readonly,
  classLabel,
  messageError,
}) => {
  const [isBlur, setIsBlur] = React.useState(false);
  const renderTooltip = (content) => <div className="p-[16px]">{content}</div>;
  const onBlur = () => {
    setIsBlur(true);
  }
  return (
    <div className={`pr-input flex flex-col gap-y-[8px] ${rootClass}`}>
      <div>
        <RenderIfComponent isValid={!!label}>
          <div className="flex items-center">
            <label
              className={`text-[18px] font-[600] text-[#2C2C33] ${
                classLabel ? classLabel : ""
              }`}
            >
              {label}
            </label>
            {required && <RequireSvg />}
            <RenderIfComponent isValid={!!tooltipContent}>
              <Tooltip
                rootClassName="p-[0px]"
                placement="bottom"
                title={renderTooltip(tooltipContent)}
              >
                <HelpSvg className="ml-[8px] cursor-pointer" />
              </Tooltip>
            </RenderIfComponent>
          </div>
        </RenderIfComponent>
        {!!subLabel?.length && (
          <span className="text-[14px] text-[#888896] font-[400]">
            {subLabel}
          </span>
        )}
      </div>
      <Input
        value={value}
        defaultValue={defaultValue}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        allowClear={allowClear}
        className={`w-full p-[16px] ${className ? className : ""}`}
        maxLength={maxLength}
        disabled={disabled}
        prefix={prefix}
        suffix={suffix}
        type={type}
        pattern={type === "email" ? "[^@\\s]+@[^@\\s]+\\.[^@\\s]+" : undefined}
        title={
          type === "email" ? "Please enter a valid email address" : undefined
        }
        status={!!error ? "error" : undefined}
        style={{
          height,
        }}
        readOnly={readonly}
      />
      <RenderIfComponent isValid={!!messageError && isBlur}>
        <div className="flex items-center gap-[4px]">
          <ErrorSvg/>
          <p className={`font-[500] text-[12px] text-[#FD5252]`}>
            {messageError}
          </p>
        </div>
      </RenderIfComponent>
    </div>
  );
};

export default PrInput;
