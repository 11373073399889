import { API_VERSION } from "contants/app.contant";
import axiosInstance from "core/axiosInstance";

const path =
  `/${API_VERSION}/medias?limit=15&page=1&order_by=DESC&sort_by=created_at&start_date=&end_date=&keyword=`;

const mediaApi = {
  getMedia() {
    return axiosInstance.post(`${path}`);
  },
  getAdvertiserMedia() {
    return axiosInstance.get(`/${API_VERSION}/advertiser/medias`);
  },
  getMediaProfile() {
    return axiosInstance.get(`${API_VERSION}/media/profile`);
  },
};

export default mediaApi;
