import React, { useRef } from "react";
import { Select, Tooltip } from "antd";
import RenderIfComponent from "components/RenderIfComponent";
import { ReactComponent as ArrowDownSvg } from "asset/svg/arrowDown.svg";
import { ReactComponent as RequireSvg } from "asset/svg/require.svg";
import { ReactComponent as HelpSvg } from "asset/svg/help.svg";
import { ReactComponent as CheckSvg } from "asset/svg/check.svg";

const PrSelect = ({
  options,
  defaultValue,
  placeholder = "Select an option",
  customIcon = <ArrowDownSvg />,
  onChange,
  allowClear = false,
  label,
  subLabel,
  rootClass,
  required = false,
  disabled = false,
  tooltipContent,
  value,
  keyValue = "value",
  keyLabel = "label",
  error,
  isColumns = false, // Prop to control column layout
  classLabel,
  height = "56px",
  readonly = false,
  itemClass = "text-[16px]",
  isSuffixIcon = true,
  align = false,
}) => {
  const selectRef = useRef(null);
  const renderTooltip = (children) => {
    return <div className="p-[16px] w-full">{children}</div>;
  };

  const labelRender = (props) => {
    const { value } = props;
    const item = options?.find((item) => item?.[keyValue] === value);
    return <div className={itemClass}>{item?.[keyLabel]}</div>;
  };

  const handleOptionClick = (selectedValue) => {
    if (onChange) {
      onChange(selectedValue);
    }
    // Close the dropdown after selection
    if (selectRef.current) {
      selectRef.current.blur();
    }
  };

  const renderColumns = () => {
    const half = Math.ceil(options.length / 2);
    const firstColumn = options.slice(0, half);
    const secondColumn = options.slice(half);

    return (
      <div className="flex p-[8px]">
        <div style={{ flex: 1 }}>
          {firstColumn.map((option) => (
            <div
              key={option?.[keyValue]}
              onClick={() => handleOptionClick(option?.[keyValue])}
              className={`p-[12px] flex items-center gap-x-[8px] cursor-pointer hover:bg-[#f9f9fb] rounded-[8px] ${
                option?.[keyValue] === value ? "text-[#2c2c33] font-[600]" : ""
              }`}
            >
              <CheckSvg
                className={`${option?.[keyValue] !== value ? "hidden" : ""}`}
              />
              {option[keyLabel]}
            </div>
          ))}
        </div>
        <div style={{ flex: 1 }}>
          {secondColumn.map((option) => (
            <div
              key={option?.[keyValue]}
              onClick={() => handleOptionClick(option?.[keyValue])}
              className={`p-[12px] flex items-center gap-x-[8px] cursor-pointer hover:bg-[#f9f9fb] rounded-[8px] ${
                option?.[keyValue] === value ? "text-[#2c2c33] font-[600]" : ""
              }`}
            >
              <CheckSvg
                className={`${option?.[keyValue] !== value ? "hidden" : ""}`}
              />
              {option?.[keyLabel]}
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div
      className={`pr-select flex flex-col gap-y-[8px] ${
        rootClass ? rootClass : ""
      }`}
    >
      <div>
        <RenderIfComponent isValid={!!label}>
          <div className="flex items-center">
            <label
              className={`text-[18px] font-[600] text-[#2C2C33] ${
                classLabel ? classLabel : ""
              }`}
            >
              {label}
            </label>
            {required && <RequireSvg />}
            <RenderIfComponent isValid={!!tooltipContent}>
              <Tooltip
                rootClassName="p-[0px]"
                placement="bottom"
                arrow={{
                  pointAtCenter: true,
                  arrowPointAtCenter: true,
                }}
                align={align}
                title={renderTooltip(tooltipContent)}
              >
                <HelpSvg className="ml-[8px] cursor-pointer" />
              </Tooltip>
            </RenderIfComponent>
          </div>
        </RenderIfComponent>
        {!!subLabel?.length && (
          <span className="text-[14px] text-[#888896] font-[400]">
            {subLabel}
          </span>
        )}
      </div>
      <Select
        ref={selectRef}
        value={value}
        defaultValue={defaultValue}
        placeholder={placeholder}
        suffixIcon={isSuffixIcon ? customIcon : false}
        onChange={onChange}
        allowClear={allowClear}
        rootClassName={`w-full`}
        labelRender={(e) => labelRender(e)}
        disabled={disabled}
        status={error ? "error" : null}
        dropdownRender={(menu) => (isColumns ? renderColumns(menu) : menu)}
        style={{
          height,
          pointerEvents: readonly ? "none" : "auto",
        }}
      >
        {!isColumns &&
          options.map((option) => (
            <Select.Option key={option?.[keyValue]} value={option?.[keyValue]}>
              <div className="flex items-center gap-x-[8px]">
                <CheckSvg
                  className={`${option?.[keyValue] !== value ? "hidden" : ""}`}
                />
                <span>{option?.[keyLabel]} </span>
              </div>
            </Select.Option>
          ))}
      </Select>
    </div>
  );
};

export default PrSelect;
