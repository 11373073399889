import { createAsyncThunk } from "@reduxjs/toolkit";
import mediaApi from "../../services/media";

//Include Both Helper File with needed methods
// import categoryApi from "api/categoryApi";

export const getCategory = createAsyncThunk("Category", async () => {
  try {
    const response = await mediaApi.getMedia();
    return response;
  } catch (error) {
    return error;
  }
});
