import { createSlice } from "@reduxjs/toolkit";
import { getCategory } from "./thunk";

export const initialState = {
  categories: [1, 2, 3, 4, 5, 6],
  isCategoriesLoading: false,
  isCategoriesSuccess: false,
};

const CategoriesSlice = createSlice({
  name: "category",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get Categories
    builder.addCase(getCategory.pending, (state, action) => {
      state.isCategoriesLoading = true;
    });
    builder.addCase(getCategory.fulfilled, (state, action) => {
      state.categories = action.payload?.data;
      state.isCategoriesSuccess = true;
      state.isCategoriesLoading = false;
    });
    builder.addCase(getCategory.rejected, (state, action) => {
      console.log('action3', action)
      state.error = action?.payload?.error || null;
      state.isCategoriesSuccess = false;
      state.isCategoriesLoading = false;
    });
  },
});

export default CategoriesSlice.reducer;
