import { combineReducers } from "redux";

import AuthReducer from "./auth/reducer";
import CategoryReducer from "./category/reducer";
import SystemReducer from "./systems/reducer";

const rootReducer = combineReducers({
  Auth: AuthReducer,
  Category: CategoryReducer,
  Systems: SystemReducer,
});

export default rootReducer;
