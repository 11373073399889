import { API_VERSION } from "contants/app.contant";
import axiosInstance from "core/axiosInstance";

const advertiserApi = {
  sendAdvertiserPressReleases(params) {
    return axiosInstance.post(
      `${API_VERSION}/advertiser/press-releases/template/send`,
      params
    );
  },
  updateSendAdvertiserPressReleases(id, params) {
    return axiosInstance.put(
      `${API_VERSION}/advertiser/press-releases/template/send/${id}`,
      params
    );
  },
  draftAdvertiserPressReleases(params) {
    return axiosInstance.post(
      `${API_VERSION}/advertiser/press-releases/template/draft`,
      params
    );
  },
  updateDraftAdvertiserPressReleases(id, params) {
    return axiosInstance.put(
      `${API_VERSION}/advertiser/press-releases/template/draft/${id}`,
      params
    );
  },
  getDetailtAdvertiserPressReleases(id) {
    return axiosInstance.get(`${API_VERSION}/advertiser/press-releases/${id}`);
  },
  draftManualAdvertiserPressReleases(params) {
    return axiosInstance.post(
      `${API_VERSION}/advertiser/press-releases/manual/draft`,
      params
    );
  },
  updateDraftManualAdvertiserPressReleases(id, params) {
    return axiosInstance.put(
      `${API_VERSION}/advertiser/press-releases/manual/draft/${id}`,
      params
    );
  },
  sendManualAdvertiserPressReleases(params) {
    return axiosInstance.post(
      `${API_VERSION}/advertiser/press-releases/manual/send`,
      params
    );
  },
  updateSendManualAdvertiserPressReleases(id, params) {
    return axiosInstance.put(
      `${API_VERSION}/advertiser/press-releases/manual/send/${id}`,
      params
    );
  },
  getAdvertiserProfile() {
    return axiosInstance.get(`${API_VERSION}/advertiser/profile`);
  },
  updateAdvertiserProfile(data) {
    return axiosInstance.put(`${API_VERSION}/advertiser/profile`, data);
  },
  getAdvertiserPressReleases(data) {
    return axiosInstance.get(`${API_VERSION}/advertiser/press-releases`, {
      params: data,
    });
  },
  getAdvertiserPressReleaseSuggestTitle(data) {
    return axiosInstance.get(
      `${API_VERSION}/advertiser/press-releases/suggest-title`,
      {
        params: data,
      }
    );
  },

  getAdvertiserPressReleaseSumary(data) {
    return axiosInstance.get(
      `${API_VERSION}/advertiser/press-releases/summary`,
      {
        params: data,
      }
    );
  },
  cancelAdvertiserPressRelease(id) {
    return axiosInstance.post(
      `${API_VERSION}/advertiser/press-releases/cancel/${id}`
    );
  },
  deleteAdvertiserPressRelease(id) {
    return axiosInstance.delete(
      `${API_VERSION}/advertiser/press-releases/${id}`
    );
  },
  getAdvertiserReportDetail(id) {
    return axiosInstance.get(
      `${API_VERSION}/advertiser/reports/press-releases/${id}`
    );
  },
  updateTitleReportDetail(id, newValue) {
    const params = {
      title: newValue,
    };
    return axiosInstance.put(
      `${API_VERSION}/advertiser/reports/press-releases/${id}`,
      params
    );
  },
  statusDetailAdvertiserPressRelease(id) {
    return axiosInstance.get(
      `${API_VERSION}/advertiser/press-releases/status-detail/${id}`
    );
  },
  rewriteDetailAdvertiserPressRelease(id) {
    return axiosInstance.get(
      `${API_VERSION}/advertiser/press-releases/rewrite/${id}`
    );
  },
  rewriteDraftAdvertiserPressRelease(params) {
    return axiosInstance.post(
      `${API_VERSION}/advertiser/press-releases/template/draft`,
      params
    );
  },
  rewriteDraftManualAdvertiserPressRelease(params) {
    return axiosInstance.post(
      `${API_VERSION}/advertiser/press-releases/manual/draft`,
      params
    );
  },
  rewriteSendAdvertiserPressRelease(params) {
    return axiosInstance.post(
      `${API_VERSION}/advertiser/press-releases/template/send`,
      params
    );
  },
  rewriteSendManualAdvertiserPressRelease(params) {
    return axiosInstance.post(
      `${API_VERSION}/advertiser/press-releases/manual/send`,
      params
    );
  },
  getAdvertiserAnalyticsPressReleases(data) {
    return axiosInstance.get(
      `${API_VERSION}/advertiser/analytics/press-releases`,
      {
        params: data,
      }
    );
  },
  getBase64Image(url) {
    return axiosInstance.get(
      `${API_VERSION}/upload/image/convert-base64?url=${url}`
    );
  },
};

export default advertiserApi;
