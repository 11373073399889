import { useLocation } from "react-router-dom";
import { useMemo } from "react";
//
import navigationConfig from "configs/navigation.config";
import Header from "./header";
import Menu from "./sideNav";
import RenderIfComponent from "components/RenderIfComponent";
import { THEME } from "contants/app.contant";
import helper from "helper";

const Index = ({ children }) => {
  const location = useLocation();  
  const breadcrumb = useMemo(() => {
    const currentPath = location.pathname;
    const matchedOption = (helper.isUserAdvertiser() ? navigationConfig.advertiserConfig : navigationConfig.mediaConfig)?.find(
      (option) => option.path === currentPath
    );

    if (!matchedOption) return "";
    return matchedOption?.breadcrumb;
  }, [location]);


  return (
    <>
      <Header />
      <Menu menuOptions={(helper.isUserAdvertiser() ? navigationConfig.advertiserConfig : navigationConfig.mediaConfig)} />
      <div className="bg-[#F9F9FB]" style={{paddingLeft: `${THEME.SIDEBAR_WIDTH}px`,paddingBottom:`${THEME.FOOTER_HEIGHT}px`}}>
        <div id="content" className="p-[40px] overflow-y-auto" style={{ height: helper.heightContent() }}>
          <RenderIfComponent isValid={!!breadcrumb?.length}>
            <div className="text-[#2C2C33] text-[32px] font-[700] mb-[24px]">{breadcrumb}</div>
          </RenderIfComponent>
          {children}
        </div>
      </div>
      <div className="fixed w-full bottom-0 z-[1] flex justify-between items-center bg-white px-[40px]" style={{height: `${THEME.FOOTER_HEIGHT}px`}}>
        <div className="flex text-[#888896] text-[12px] gap-x-[4px]">
          <div className="font-[700]">문의</div>
          <div>info@prhub.kr</div>
        </div>
        <div className="text-[#888896] text-[12px]" style={{letterSpacing: '-0.12px'}}>
        © 2024 TDI Play Co., Ltd. All Rights Reserved.
        </div>
      </div>
    </>
  );
};
export default Index;
