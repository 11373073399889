import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import helper from "helper";
import { THEME } from "contants/app.contant";

const SideNav = ({ menuOptions }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleMenuClick = (path) => {
    navigate(path);
  };

  function find(url, prefix) {
    if (typeof url === "string" && url && url.startsWith(prefix)) {
      return true;
    }
    return false;
  }

  const activeMenu = useCallback(
    (item) => {
      return find(location.pathname,item.path);
    },
    [location]
  );
  return (
    <div className="w-full overflow-y-auto px-[24px] py-[10px] bg-white fixed border-r-[1px] border-[#EFEFF5] z-[1] flex justify-center items-center" style={{height: helper.heightContent(),maxWidth:`${THEME.SIDEBAR_WIDTH}px` }}>
      <div className="menu flex flex-col gap-y-[40px]">
        {menuOptions?.map((item) => {
          // if (item.roles && !item.roles.includes(item.userRole)) {
          //   return null;
          // }
          return (
            <div
              key={item.key}
              className={`flex flex-col justify-center items-center gap-y-[4px] cursor-pointer menu ${
                activeMenu(item) ? "text-[#EC2929]" : "text-[#6F6F7B]"
              }`}
              onClick={() => handleMenuClick(item.path)} 
            >
              <div
                className={`${
                  activeMenu(item) ? "bg-[#FEEFEF] rounded-[12px] active" : ""
                } flex justify-center items-center w-[48px] h-[48px] menu-item`}
              >
                {item.icon}
              </div>
              <div className="text-[16px] font-[500] whitespace-nowrap">
                {item.title}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default SideNav;
