import React, { useState, useEffect } from "react";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import ko from "date-fns/locale/ko";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { isAfter } from "date-fns";
import { Tooltip } from "antd";
//
import { ReactComponent as CalendarSvg } from "asset/svg/calendar.svg";
import { ReactComponent as ArrowLeftSvg } from "asset/svg/arrowLeft.svg";
import { ReactComponent as ArrowRightSvg } from "asset/svg/arrowCalandarRight.svg";
import RenderIfComponent from "components/RenderIfComponent";
import { ReactComponent as RequireSvg } from "asset/svg/require.svg";
import { ReactComponent as HelpSvg } from "asset/svg/help.svg";

registerLocale("ko", ko);
setDefaultLocale("ko");

const PrRangeDatePicker = ({
  startDate: initialStartDate,
  endDate: initialEndDate,
  onChange,
  timeRequest,
  wrapperClassName,
  placeholderStart = "시작일",
  placeholderEnd = "마지막일",
  label,
  subLabel,
  rootClass,
  required = false,
  tooltipContent,
  classLabel,
  className,
}) => {
  const [focusStart, setFocusStart] = React.useState(false);
  const [focusEnd, setFocusEnd] = React.useState(false);
  const [startDate, setStartDate] = useState(initialStartDate || undefined);
  const [endDate, setEndDate] = useState(initialEndDate || undefined);

  useEffect(() => {
    setStartDate(initialStartDate || null);
  }, [initialStartDate, timeRequest]);

  useEffect(() => {
    setEndDate(initialEndDate || null);
  }, [initialEndDate, timeRequest]);

  const handleStartDateChange = (date) => {
    setStartDate(date);
    if (date && endDate && isAfter(date, endDate)) {
      setEndDate(undefined);
      onChange(date, undefined);
    } else {
      onChange(date, endDate);
    }
  };

  const handleEndDateChange = (date) => {
    if (startDate && date && isAfter(startDate, date)) {
      setEndDate(startDate);
      onChange(startDate, startDate);
    } else {
      setEndDate(date);
      onChange(startDate, date);
    }
  };

  function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }

  const renderHeaderCalandar = ({
    date,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }) => (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-x-[8px]">
        <div className="text-[14px] text-[#2C2C33] font-[600]">
          {
            dayjs(date).format("YYYY년 M월")
          }
        </div>
        <RenderIfComponent isValid={dayjs(date).format("YYYY-MM-DD") === dayjs().format("YYYY-MM-DD")}>
          <div className="text-[10px] font-[500] text-[#555560] py-[2px] px-[6px] border border-[#E1E1EB] rounded-[6px] w-fit">
            오늘
          </div>
        </RenderIfComponent>
      </div>
      <div className="flex gap-x-[8px] items-center">
        <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled} className="min-w-[24px] min-h-[24px]">
          <ArrowLeftSvg />
        </button>
        <button onClick={increaseMonth} disabled={nextMonthButtonDisabled} className="min-w-[24px] min-h-[24px]">
          <ArrowRightSvg />
        </button>
      </div>
    </div>
  )

  const renderTooltip = (content) => <div className="p-[16px]">{content}</div>;

  return (
    <div className={`flex flex-col gap-y-[8px] ${rootClass}`}>
      <div>
        <RenderIfComponent isValid={!!label}>
          <div className="flex items-center">
            <label className={`text-[18px] font-[600] text-[#2C2C33] ${classLabel && classLabel}`}>
              {label}
            </label>
            {required && <RequireSvg />}
            <RenderIfComponent isValid={!!tooltipContent}>
              <Tooltip
                rootClassName="p-[0px]"
                placement="bottom"
                title={renderTooltip(tooltipContent)}
              >
                <HelpSvg className="ml-[8px] cursor-pointer" />
              </Tooltip>
            </RenderIfComponent>
          </div>
        </RenderIfComponent>
        {!!subLabel?.length && (
          <span className="text-[14px] text-[#888896] font-[400]">
            {subLabel}
          </span>
        )}
      </div>
      <div className="react-date-custom flex">
        <DatePicker
          locale={"ko"}
          selected={startDate}
          onChange={(date) => handleStartDateChange(date)}
          selectsStart
          startDate={isValidDate(startDate) ? startDate : null}
          endDate={isValidDate(endDate) ? endDate : null}
          dateFormat={"yyyy-MM-dd"}
          dateFormatCalendar="yyyy년 M월"
          showIcon
          icon={<CalendarSvg />}
          renderCustomHeader={renderHeaderCalandar}
          placeholderText={placeholderStart}
          wrapperClassName={`${focusStart ? "focus" : ""} ${wrapperClassName}`}
          onFocus={() => setFocusStart(true)}
          onBlur={() => setFocusStart(false)}
        />
        <div className="text-[#C5C8CE] font-[400] mx-[4px]">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path d="M4 8H12" stroke="#B4B4BD" strokeWidth="1.5" strokeLinecap="round"/>
        </svg>
        </div>
        <DatePicker
          selected={endDate}
          onChange={(date) => handleEndDateChange(date)}
          selectsEnd
          startDate={isValidDate(startDate) ? startDate : null}
          endDate={isValidDate(endDate) ? endDate : null}
          minDate={startDate}
          showIcon
          dateFormatCalendar="yyyy년 M월"
          icon={<CalendarSvg />}
          dateFormat={"yyyy-MM-dd"}
          placeholderText={placeholderEnd}
          renderCustomHeader={renderHeaderCalandar}
          onFocus={() => setFocusEnd(true)}
          onBlur={() => setFocusEnd(false)}
          wrapperClassName={`${focusEnd ? "focus" : ""} ${wrapperClassName}`}
          className={className}
        />
      </div>
    </div>
  );
};

export default PrRangeDatePicker;
