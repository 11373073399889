import React, { useEffect, useState } from "react";
import { Input, Tooltip } from "antd";
import RenderIfComponent from "components/RenderIfComponent";
import { ReactComponent as RequireSvg } from "asset/svg/require.svg";
import { ReactComponent as HelpSvg } from "asset/svg/help.svg";
import { ReactComponent as CloseSvg } from "asset/svg/close.svg";

const PrInputTags = ({
  value,
  placeholder = "Enter text",
  label,
  subLabel,
  rootClass = "",
  required = false,
  tooltipContent,
  maxLength = 50,
  disabled = false,
  prefix,
  suffix,
  maxTags = 10,
  error,
  onChange,
  isHiddenAction,
  height = "56px",
  classLabel
}) => {
  const [tags, setTags] = useState(new Set(value));
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputKeyDown = (e) => {
    if (e.key === "Enter" && inputValue.trim() !== "") {
      e.preventDefault();
      const trimmedInput = inputValue.trim();
      if (tags.size < maxTags && !tags.has(trimmedInput)) {
        const newTags = new Set(tags);
        newTags.add(trimmedInput);
        setTags(newTags);
        setInputValue("");
        if (onChange) {
          onChange(Array.from(newTags)); // Notify parent component of the updated tags
        }
      }
    }
  };

  const handleTagClose = (tagToRemove) => {
    const newTags = new Set(tags);
    newTags.delete(tagToRemove);
    setTags(newTags);
    if (onChange) {
      onChange(Array.from(newTags)); // Notify parent component of the updated tags
    }
  };

  const renderTooltip = (content) => <div className="p-[16px]">{content}</div>;

  useEffect(() => {
    setTags(new Set(value || []));
  }, [value]);

  return (
    <div className={`pr-input-tags flex flex-col gap-y-[8px] ${rootClass}`}>
      <div>
        <RenderIfComponent isValid={!!label}>
          <div className="flex items-center">
            <label className={`text-[18px] font-[600] text-[#2C2C33] ${classLabel ? classLabel : ""}`}>
              {label}
            </label>
            {required && <RequireSvg />}
            <RenderIfComponent isValid={!!tooltipContent}>
              <Tooltip
                rootClassName="p-[0px]"
                placement="bottom"
                title={renderTooltip(tooltipContent)}
              >
                <HelpSvg className="ml-[8px] cursor-pointer" />
              </Tooltip>
            </RenderIfComponent>
          </div>
        </RenderIfComponent>
        {!!subLabel?.length && (
          <span className="text-[14px] text-[#888896] font-[400]">
            {subLabel}
          </span>
        )}
      </div>
      <RenderIfComponent isValid={!isHiddenAction}>
        <Input
          value={inputValue}
          placeholder={placeholder}
          onChange={handleInputChange}
          onKeyUp={handleInputKeyDown}
          allowClear={false}
          className="w-full p-[16px]"
          maxLength={maxLength}
          disabled={disabled}
          prefix={prefix}
          suffix={suffix}
          type="text"
          status={error ? "error" : undefined}
          style={{
            height
          }}
        />
      </RenderIfComponent>
      <div>
        <div className="flex flex-wrap gap-[8px]">
          {Array.from(tags).map((tag, index) => (
            <div
              key={index}
              className="bg-[#EFEFF5] rounded-[8px] px-[8px] py-[4px] flex items-center justify-center gap-x-[4px] text-[13px] text-[#555560] h-[29px]"
            >
              {tag}
              <RenderIfComponent isValid={!isHiddenAction}>
                <div
                  className="cursor-pointer"
                  onClick={() => handleTagClose(tag)}
                >
                  <CloseSvg />
                </div>
              </RenderIfComponent>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PrInputTags;
