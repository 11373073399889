import { useNavigate } from "react-router-dom";
//
import PrButton from "components/PrButton";
import { ReactComponent as LogoSvg } from "asset/svg/logo.svg";
import { ReactComponent as EditSvg } from "asset/svg/edit.svg";
import { ReactComponent as LineSvg } from "asset/svg/line.svg";
import { ReactComponent as PointSvg } from "asset/svg/point.svg";
import ProfileDropdown from "components/profile/ProfileDropdown";
import helper from "helper";
import RenderIfComponent from "components/RenderIfComponent";
const HeaderLayout = () => {
  const navigate = useNavigate();
  const isUserAdvertiser = helper.isUserAdvertiser();

  return (
    <div className="sticky top-0 flex items-center justify-between h-[80px] bg-white px-[40px] border-b-[1px] border-[#EFEFF5] z-[99]">
      <LogoSvg className="cursor-pointer" onClick={() => navigate("/")}/>
      <div className="flex gap-x-[20px] items-center">
        <RenderIfComponent isValid={isUserAdvertiser}>
          <PrButton
            type="primary"
            htmlType={"button"}
            height={"48px"}
            width="167px"
            onClick={() => navigate("/advertiser/press-release/template")}
          >
            <EditSvg className="min-w-[24px] min-h-[24px]" />{" "}
            <span className="text-[16px] font-[500] leading-[24px]">
              보도자료 작성
            </span>
          </PrButton>
          <LineSvg />
          <div className="flex gap-x-[8px] items-center text-[16px] font-[600]">
            <PointSvg />
            2,800
          </div>
          <LineSvg />
        </RenderIfComponent>
        <ProfileDropdown />
      </div>
    </div>
  );
};

export default HeaderLayout;
