import { ReactComponent as WriteSvg } from "asset/svg/sideNav/write.svg";
import { ReactComponent as InquirySvg } from "asset/svg/sideNav/inquiry.svg";
import { ReactComponent as AnalysisSvg } from "asset/svg/sideNav/analysis.svg";
import { ReactComponent as AdjustmentSvg } from "asset/svg/sideNav/adjustment.svg";

const advertiserConfig = [
  {
    key: "1",
    path: "/advertiser/press-release",
    title: "보도자료",
    roles: ["admin", "user"],
    icon: <WriteSvg />,
    breadcrumb: "보도자료 작성",
  },
  {
    key: "2",
    path: "/analysis",
    title: "결과분석",
    roles: ["user"],
    icon: <AnalysisSvg />,
    breadcrumb: "결과분석",
  },
  {
    key: "3",
    path: "/inquiry",
    title: "문의",
    roles: ["admin"],
    icon: <InquirySvg />,
  },
];

const mediaConfig = [
  {
    key: "1",
    path: "/media/press-release",
    title: "보도자료",
    roles: ["admin", "user"],
    icon: <WriteSvg />,
    breadcrumb: "보도자료",
  },
  // {
  //   key: "2",
  //   path: "/adjustment",
  //   title: "정산",
  //   roles: ["user"],
  //   icon: <AdjustmentSvg />,
  //   breadcrumb: "정산",
  // },
  {
    key: "3",
    path: "/inquiry",
    title: "문의",
    roles: ["admin"],
    icon: <InquirySvg />,
  },
];

const navigationConfig = {
  advertiserConfig,
  mediaConfig,
};
export default navigationConfig
