import { createSlice } from "@reduxjs/toolkit";
import { login } from "./thunk";

export const initialState = {
  data: {
    admin: { email: "", id: "", name: "", role_id: "" },
    meta: {
      access_token: {
        token: "",
        ttl: "",
      },
      refresh_token: {
        token: "",
        ttl: "",
      },
    },
  },
  isAuthLoading: false,
  isAuthSuccess: false,
};

const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get Auth
    builder.addCase(login.pending, (state, action) => {
      state.isAuthLoading = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.data = action.payload?.data;
      state.isAuthSuccess = true;
      state.isAuthLoading = false;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.error = action?.payload?.error || null;
      state.isAuthSuccess = false;
      state.isAuthLoading = false;
    });
  },
});

export default AuthSlice.reducer;
