import React from "react";
import { Tooltip } from "antd";
import dayjs from "dayjs";
import RenderIfComponent from "components/RenderIfComponent";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { parse } from "date-fns";
import { forwardRef } from "react";
//
import { ReactComponent as RequireSvg } from "asset/svg/require.svg";
import { ReactComponent as HelpSvg } from "asset/svg/help.svg";
import { FORMAT_DEFAULT_REACT_DATEPCIKER } from "contants/app.contant";
import { ReactComponent as ArrowLeftSvg } from "asset/svg/arrowLeft.svg";
import { ReactComponent as ArrowRightSvg } from "asset/svg/arrowCalandarRight.svg";

const CustomInput = forwardRef(({ readonly,placeholderText,value, onClick, onChange }, ref) => {
    const formattedValue = value ? value?.replace(" - ", "~") : "";
  
    return (
      <input
        readOnly={readonly}
        className={`custom-input !text-[16px] ${readonly ? "pointer-events-none" : "pointer-events-auto"}`}
        onClick={onClick}
        onChange={onChange}
        ref={ref}
        value={formattedValue || ""}
        placeholder={placeholderText}
      />
    );
  });

const PrSingleRangePicker = ({
  placeholder = "Select date",
  onChange,
  label,
  subLabel,
  rootClass = "",
  required = false,
  tooltipContent,
  disabled = false,
  className,
  format = FORMAT_DEFAULT_REACT_DATEPCIKER, // default date format
  error,
  suffixIcon = false,
  readonly,
  wrapperClassName,
  startDate,
  endDate,
  classLabel,
  popperPlacement = "bottom-start" /* type AlignedPlacement = "top-start" | "top-end" | "right-start" | "right-end" | "bottom-start" | "bottom-end" | "left-start" | "left-end" */,
}) => {
  const renderTooltip = (content) => <div className="p-[16px]">{content}</div>;

  const renderHeaderCalandar = ({
    date,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }) => (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-x-[8px]">
        <div className="text-[14px] text-[#2C2C33] font-[600]">
          {dayjs(date).format("YYYY년 M월")}
        </div>
        <RenderIfComponent
          isValid={
            dayjs(date).format("YYYY-MM-DD") === dayjs().format("YYYY-MM-DD")
          }
        >
          <div className="text-[10px] font-[500] text-[#555560] py-[2px] px-[6px] border border-[#E1E1EB] rounded-[6px] w-fit">
            오늘
          </div>
        </RenderIfComponent>
      </div>
      <div className="flex gap-x-[8px] items-center">
        <button
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}
          className="min-w-[24px] min-h-[24px]"
        >
          <ArrowLeftSvg />
        </button>
        <button
          onClick={increaseMonth}
          disabled={nextMonthButtonDisabled}
          className="min-w-[24px] min-h-[24px]"
        >
          <ArrowRightSvg />
        </button>
      </div>
    </div>
  );

  const isDateValid = (dateString) => {
    const datePattern = /^\d{4}.\d{2}.\d{2}$/;
    const [startDateStr, endDateStr] = dateString
      ?.split("~")
      ?.map((str) => str.trim());

    return datePattern.test(startDateStr) && datePattern.test(endDateStr);
  };

  const parseDateFromString = (dateString) => {
    const [startDateStr, endDateStr] = dateString
      ?.split("~")
      ?.map((str) => str.trim());
    const parsedStartDate = parse(startDateStr, "yyyy.MM.dd", new Date());
    const parsedEndDate = parse(endDateStr, "yyyy.MM.dd", new Date());
    return isNaN(parsedStartDate.getTime()) || isNaN(parsedEndDate.getTime())
      ? undefined
      : [parsedStartDate, parsedEndDate];
  };

  const handleDateChangeRaw = (e) => {
    const value = e.target.value;
    if (!value) return;
    if (isDateValid(value)) {
      const [parsedStartDate, parsedEndDate] = parseDateFromString(value) || [];
      onChange([parsedStartDate, parsedEndDate]);
    }
  };

  return (
    <div className={`flex flex-col gap-y-[8px] ${rootClass}`}>
      <div>
        <RenderIfComponent isValid={!!label}>
          <div className="flex items-center">
            <label className={`text-[18px] font-[600] text-[#2C2C33] ${classLabel ? classLabel : ''}`}>
              {label}
            </label>
            {required && <RequireSvg />}
            <RenderIfComponent isValid={!!tooltipContent}>
              <Tooltip
                rootClassName="p-[0px]"
                placement="bottom"
                title={renderTooltip(tooltipContent)}
              >
                <HelpSvg className="ml-[8px] cursor-pointer" />
              </Tooltip>
            </RenderIfComponent>
          </div>
        </RenderIfComponent>
        {!!subLabel?.length && (
          <span className="text-[14px] text-[#888896] font-[400]">
            {subLabel}
          </span>
        )}
      </div>
      <div
        className={`react-date-custom flex w-full h-full ${
          error ? "error" : ""
        }`}
      >
        <DatePicker
          selectsRange
          startDate={startDate}
          endDate={endDate}
          locale={"ko"}
          selected={startDate}
          onChange={(date) => {
            onChange(date);
          }}
          onChangeRaw={(e) => handleDateChangeRaw(e)}
          dateFormat={format}
          dateFormatCalendar="yyyy년 M월"
          showIcon={!!suffixIcon}
          icon={suffixIcon}
          renderCustomHeader={renderHeaderCalandar}
          placeholderText={placeholder}
          wrapperClassName={`${
            wrapperClassName ? wrapperClassName : ""
          } w-full h-[56px]`}
          disabled={disabled}
          popperPlacement={popperPlacement}
          className={`w-full p-[16px] !text-[16px] ${
            readonly ? "pointer-events-none" : "pointer-events-auto"
          } ${className ? className : ""}`}
          customInput={<CustomInput placeholderText={placeholder} readonly={readonly}/>}
        />
      </div>
    </div>
  );
};

export default PrSingleRangePicker;
