import { createSlice } from "@reduxjs/toolkit";
import { getConfigs } from "./thunk";

export const initialState = {
  configs: {
    industries: [],
    config: {
      client_privacy_policy: "",
      client_terms_of_service: "",
    },
  },
  isConfigLoading: false,
  isConfigSuccess: false,
};

const ConfigSlice = createSlice({
  name: "Systems",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get Categories
    builder.addCase(getConfigs.pending, (state, action) => {
      state.isConfigLoading = true;
    });
    builder.addCase(getConfigs.fulfilled, (state, action) => {
      state.configs = action.payload?.data;
      state.isConfigSuccess = true;
      state.isConfigLoading = false;
    });
    builder.addCase(getConfigs.rejected, (state, action) => {
      state.error = action?.payload?.error || null;
      state.isConfigSuccess = false;
      state.isConfigLoading = false;
    });
  },
});

export default ConfigSlice.reducer;
