import { Modal } from "antd";
import React, { createContext, useState, useCallback, useContext } from "react";
import PrButton from "components/PrButton";
import { ReactComponent as CloseSvg } from "asset/svg/closeModal.svg";

const ModalContext = createContext(undefined);

export const ConfirmContextProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [setting, setSetting] = useState({ message: "", loading: false });
  const [onSubmit, setOnSubmit] = useState(null);

  const showConfirm = useCallback((props, onSubmit) => {
    setSetting({ ...props, loading: false });
    setOnSubmit(() => onSubmit);
    setOpen(true);
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = async () => {
    try {
      setSetting((prev) => ({ ...prev, loading: true }));
      await onSubmit(); 
      handleClose(); 
    } catch (error) {
      setSetting((prev) => ({ ...prev, loading: false }));
    } finally {
      setSetting((prev) => ({ ...prev, loading: false }));
    }
  };

  return (
    <ModalContext.Provider value={{ showConfirm }}>
      {children}
      <Modal
        width={412}
        open={open}
        centered={true}
        onClose={handleClose}
        footer={false}
        closable={false}
        zIndex={99999}
        styles={{
          content: {
            padding: "24px",
            borderRadius: "24px",
            border: "1px solid #E1E1EB",
            boxShadow: "0px 0px 24px 0px rgba(106, 107, 121, 0.12)",
          },
          footer: {
            marginTop: "0px",
          },
        }}
      >
        <div>
          <div className="flex justify-end mb-[8px]">
            <CloseSvg
                className="cursor-pointer"
                onClick={() => handleClose()}
              />
          </div>
          <div className="flex items-center justify-between">
            <div className="min-w-[24px] min-h-[24px]"></div>
            <div className="text-center font-[700] text-[#222] text-[24px]">
              {setting.title}
            </div>
            <div></div>
          </div>
          <div className="text-center text-[#5F646F] mt-[16px]">
            {setting.message}
          </div>
          <div className="flex items-center justify-center gap-x-[10px] mt-[40px]">
            {!setting.isHiddenClose && (
              <PrButton
                type="primary"
                onClick={handleClose}
                width="100%"
                defaultColor="#EFEFF5"
              >
                <span className="text-[#555560] text-[16px] font-[500]">
                  {setting.cancelButtonText || "취소"}
                </span>
              </PrButton>
            )}
            <PrButton
              type="primary"
              onClick={handleConfirm}
              width="100%"
              label={setting.submitButtonText || "확인"}
              loading={setting.loading} 
            />
          </div>
        </div>
      </Modal>
    </ModalContext.Provider>
  );
};

export const useConfirm = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error("useConfirm must be used within a ConfirmContextProvider");
  }
  return context;
};
